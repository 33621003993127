import React from "react";
import eventRefs from "../../assets/eventRef";
import { Link } from "react-router-dom";

export default class DeviceAlerts extends React.Component {
  state = {
    searchBar: "",
    currentFilter: "",
    eventNameFilter: "",
    eventTriggeredFilter: "",
    sortFilter: ""
  };
  applyFilters = () => {
    this.setState({
      currentFilter: this.state.searchBar,
    });
  };
  componentDidMount() {
    // console.log(this.props)
    // this.props.fetchResource("actions")
    if (
      !this.props.resources.eventsLoaded &&
      !this.props.resources.eventsLoading
    ) {
      this.props.fetchResource("events");
    }
  }
  render() {
    const { events, devices } = this.props.resources;

    const sortedEvents = [
      ...events.filter((e) => e.triggered),
      ...events.filter((e) => !e.triggered),
    ];

    const filteredSortedEvents = sortedEvents.filter((event) => {
      const foundEventRef =
        eventRefs.find((er) => er.code === event.code) || {};
      const eventRefName = foundEventRef.name;
      const eventParameters = (event.params || []).join("");
      if (this.state.eventNameFilter) {
        if (this.state.eventNameFilter != eventRefName) {
          return false
        }
      }
      if (this.state.eventTriggeredFilter) {
        if (this.state.eventTriggeredFilter === "OK" && event.triggered) {
          return false
        }
        if (this.state.eventTriggeredFilter === "Alert" && !event.triggered) {
          return false
        }
      }
      const foundDevice =
        devices.find(
          (d) => d.enclosureSerialNumber == event.enclosureSerialNumber
        ) || {};
      return `${eventRefName}${event.name}${event.enclosureSerialNumber}${
        foundDevice && (foundDevice.deviceName || "")
      }${eventParameters}`
        .toLowerCase()
        .includes(this.state.currentFilter.toLowerCase());
    });

    let finalFilteredSortedEvents;
    if (this.state.sortFilter) {
      finalFilteredSortedEvents = filteredSortedEvents.sort((a, b) => {
        // const foundEventRefA = eventRefs.find((er) => er.code === a.code) || {};
        // const foundEventRefB = eventRefs.find((er) => er.code === b.code) || {};
        const firstParamA = (a.params || [])[0] || "";
        const firstParamB = (b.params || [])[0] || "";
        const secondParamA = (a.params || [])[1] || "";
        const secondParamB = (b.params || [])[1] || "";
        const thirdParamA = (a.params || [])[2] || "";
        const thirdParamB = (b.params || [])[2] || "";

        if (this.state.sortFilter === "param1_asc") {
          return firstParamA.localeCompare(firstParamB);
        } else if (this.state.sortFilter === "param1_desc") {
          return firstParamB.localeCompare(firstParamA);
        } else if (this.state.sortFilter === "param2_asc") {
          return secondParamA.localeCompare(secondParamB);
        } else if (this.state.sortFilter === "param2_desc") {
          return secondParamB.localeCompare(secondParamA);
        } else if (this.state.sortFilter === "param3_asc") {
          return thirdParamA.localeCompare(thirdParamB);
        } else if (this.state.sortFilter === "param3_desc") {
          return thirdParamB.localeCompare(thirdParamA);
        } else {
          return 0;
        }

      })
    } else {
      finalFilteredSortedEvents = filteredSortedEvents
    }

    const eventNameOptions = sortedEvents.reduce((sum, item) => {
      let foundEventRefName = eventRefs.find((er) => er.code === item.code)?.name
      if (foundEventRefName) {
        if (!sum.includes(foundEventRefName)) {
          sum.push(foundEventRefName);
        }
      }
      return sum;

    }, [])

    const columnWidths = [
      9, //0
      18, //1
      8, //2
      6, //3
      6, //4
      6, //5
      9, //6
      12, //7
      4, //8
    ];
    return (
      <div
        style={{
          overflowX: "auto",
        }}
      >
        <div
          className="grid-x"
          style={{ marginBottom: "10px", marginTop: "20px" }}
        >
          <div className="cell show-for-small-only">
            {this.props.match.params.enclosureSerialNumber ? (
              <div className="">
                <div
                  style={{
                    // width: "100%",
                    marginTop: "20px",
                    // display: 'flex',
                    // justifyContent: 'flex-end',
                    // alignItems: 'center',
                    // padding: "10px"
                  }}
                >
                  <Link
                    to={`/devices/connected/${this.props.match.params.enclosureSerialNumber}/alerts/new`}
                  >
                    <div
                      className="button"
                      style={{
                        marginBottom: "10px",
                        width: "100%",
                      }}
                    >
                      <span>+ Create New Alert Event</span>
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  // justifyContent: 'flex-end',
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "20px",
                  // padding: "10px"
                }}
              >
                <div style={{ width: "100%" }}>
                  <select
                    style={{
                      marginBottom: "0px",
                      // backgroundColor: "#223647",
                      // color: "white",
                      // border: "none",
                      // width: "100%",
                      width: "300px"
                    }}
                    onChange={(e) => {
                      if (e.target.value) {
                        this.props.history.push(
                          `/devices/connected/${e.target.value}/alerts/new`
                        );
                      }
                    }}
                  >
                    <option value="">Create New Alert for Device</option>
                    {devices.map((device, index) => {
                      return (
                        <option
                          key={index}
                          value={device.enclosureSerialNumber}
                          disabled={
                            device.connectionState === "Disconnected"
                              ? true
                              : false
                          }
                        >
                          {device.deviceName === "SynLink PDU" ||
                          !device.deviceName
                            ? `PDU SN:${device.enclosureSerialNumber || ""}`
                            : device.deviceName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
          </div>
          <div className="cell large-8 medium-8">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // marginBottom: "10px",
              }}
            >
              <input
                type="text"
                style={{
                  margin: "0px",
                }}
                placeholder="Search for device name, serial number, event name, event parameter"
                value={this.state.searchBar}
                onChange={(e) => {
                  this.setState({ searchBar: e.target.value });
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    this.applyFilters();
                  }
                }}
              />
              <span>
                <span
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    padding: "0.45rem",
                    height: "2.43rem",
                    margin: "0 0 1rem",
                    marginBottom: "0px",
                    borderRadius: "0.1875rem",
                    // backgroundColor: "#49535E",
                    // color: "#fff",
                  }}
                  className="background-color-secondary"
                  onClick={() => {
                    this.applyFilters();
                  }}
                >
                  <i className="material-icons" style={{ marginRight: "0px" }}>
                    search
                  </i>
                </span>

              </span>
            </div>
          </div>
          
          <div className="cell large-4 medium-4 hide-for-small-only">
            {this.props.match.params.enclosureSerialNumber ? (
              <div className="" style={{ display: 'flex', justifyContent: "flex-end"}}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    width: "300px"
                    // padding: "10px"
                  }}
                >
                  <div>
                    <Link
                      to={`/devices/connected/${this.props.match.params.enclosureSerialNumber}/alerts/new`}
                    >
                      <div
                        className="button"
                        style={{
                          marginBottom: "0px",
                        }}
                      >
                        <span>+ Create New Alert Event</span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  // padding: "10px"
                }}
              >
                <div>
                  <select
                    style={{
                      marginBottom: "0px",
                      // backgroundColor: "#223647",
                      // color: "white",
                      // border: "none",
                      width: "300px"
                    }}
                    onChange={(e) => {
                      if (e.target.value) {
                        this.props.history.push(
                          `/devices/connected/${e.target.value}/alerts/new`
                        );
                      }
                    }}
                  >
                    <option value="">Create New Alert for Device</option>
                    {devices.map((device, index) => {
                      return (
                        <option
                          key={index}
                          value={device.enclosureSerialNumber}
                          disabled={
                            device.connectionState === "Disconnected"
                              ? true
                              : false
                          }
                        >
                          {device.deviceName === "SynLink PDU" ||
                          !device.deviceName
                            ? `PDU SN:${device.enclosureSerialNumber || ""}`
                            : device.deviceName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="grid-x">
          <div className="cell large-6">
            <div
              style={{
                fontSize: "14px",
                color: "#6b6b6b",
                marginBottom: "5px",
                marginLeft: "3px",
              }}
            >
              Filter by
            </div>
            <div
              style={{
                display: 'flex',
                marginBottom: "20px"
              }}
            >
              <span style={{
                marginRight: "10px"
              }}>
                  <select
                    style={{
                      margin: "0px",
                    }}
                    onChange={(e) => {
                      this.setState({
                        eventNameFilter: e.target.value
                      })
                    }}
                  >
                    <option value="">All Event Types</option>
                    <optgroup label="Filter by">
                      {eventNameOptions.map((name) => {
                        return (
                          <option key={name} value={name}>
                            {name}
                          </option>
                        );
                      })}

                    </optgroup>
                  </select>

                </span>
              <span>
                <select
                  style={{
                    // backgroundColor: "transparent",
                    // border: "none",
                    // cursor: 'pointer',
                    margin: "0px",
                    // color: "#6b6b6b",
                    // fontSize: "14px",
                  }}
                  onChange={(e) => {
                    this.setState({
                      eventTriggeredFilter: e.target.value,
                    });
                  }}
                >
                  <option value="">All Status</option>
                  <optgroup label="Filter by">
                    <option value="OK">OK (Normal)</option>
                    <option value="Alert">Alert Activated</option>
                  </optgroup>
                </select>
              </span> 
            </div>
          </div>
          <div className="cell large-6">
            <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
              <span>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#6b6b6b",
                    marginBottom: "5px",
                    marginLeft: "3px",
                  }}
                >
                  Sort by
                </div>
                <select
                  onChange={(e) => {
                    this.setState({
                      sortFilter: e.target.value
                    })
                  }}
                >
                  <option value="">Default</option>
                  <optgroup label="Parameter 1">
                    <option value="param1_asc">Parameter 1 ↑</option>
                    <option value="param1_desc">Parameter 1 ↓</option>
                  </optgroup>
                  <optgroup label="Parameter 2">
                    <option value="param2_asc">Parameter 2 ↑</option>
                    <option value="param2_desc">Parameter 2 ↓</option>
                  </optgroup>
                  <optgroup label="Parameter 3">
                    <option value="param3_asc">Parameter 3 ↑</option>
                    <option value="param3_desc">Parameter 3 ↓</option>
                  </optgroup>
                </select>

              </span>
            </div>
          </div>
        </div>
        
        <div className="grid-row header">
          {/* <div style={{ flex: columnWidths[0] }}>Category</div> */}
          <div style={{ flex: columnWidths[1] }}>
            Event Name
          </div>
          <div style={{ flex: columnWidths[2] }}>
{/* 
            <span>
              <select
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: 'pointer',
                  margin: "0px",
                  color: "#6b6b6b",
                  fontSize: "14px",
                }}
                onChange={(e) => {
                  this.setState({
                    eventTriggeredFilter: e.target.value,
                  });
                }}
              >
                <option value="">Status</option>
                <optgroup label="Filter by">
                  <option value="OK">OK (Normal)</option>
                  <option value="Alert">Alert Activated</option>
                </optgroup>
              </select>
            </span>  */}
            Status

          </div>
          <div
            style={{ flex: columnWidths[3], cursor: "pointer" }}
          >
              Parameter 1
          </div>
          <div style={{ flex: columnWidths[4] }}>Parameter 2</div>
          <div style={{ flex: columnWidths[5] }}>Parameter 3</div>
          {/* <div style={{ flex: columnWidths[6] }}>Actions</div> */}
          <div style={{ flex: columnWidths[7] }}>PDU</div>
          {/* <div style={{ flex: columnWidths[8] }}></div> */}
        </div>
        {this.props.resources.eventsLoading ? (
          <Loading columnWidths={columnWidths} />
        ) : sortedEvents.length === 0 ? (
          <div className="grid-row-wrapper">
            <div className="grid-row">
              <div style={{ paddingLeft: "30px" }}>No Events Found</div>
            </div>
          </div>
        ) : null}
        {finalFilteredSortedEvents.map((event, index) => {
          const foundEventRef =
            eventRefs.find((er) => er.code === event.code) || {};
          const firstParamUnit = ((foundEventRef.params || [])[0] || {}).unit;
          const secondParamUnit = ((foundEventRef.params || [])[1] || {}).unit;
          const thirdParamUnit = ((foundEventRef.params || [])[2] || {}).unit;

          const firstParamTitle = ((foundEventRef.params || [])[0] || {}).label;
          const secondParamTitle = ((foundEventRef.params || [])[1] || {}).label;
          const thirdParamTitle = ((foundEventRef.params || [])[2] || {}).label;

          const eventRefName = foundEventRef.name;

          const device =
            devices.find(
              (d) => d.enclosureSerialNumber == event.enclosureSerialNumber
            ) || {};

          return (
            <div key={index} className="grid-row-wrapper">
              <div className="grid-row" style={{}}>
                <div
                  style={{
                    flex: columnWidths[1],
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                  }}
                >
                  <Link
                    to={`/devices/connected/${device.enclosureSerialNumber}/alerts/${event.id}`}
                    // to={`/devices/connected/${device.enclosureSerialNumber}/alerts`}
                  >
                    <React.Fragment>
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "center",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "600",
                            textDecoration: "underline",
                          }}
                        >
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <span>{eventRefName}</span>
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            ></span>
                          </span>
                        </span>
                        <span>{event.name}</span>
                      </span>
                    </React.Fragment>
                  </Link>
                </div>

                <div
                  style={{ flex: columnWidths[2] }}
                  title={event.triggered ? "ALERT" : "OK"}
                >
                  <div
                    className="spreadsheet-badge"
                    style={{
                      backgroundColor: event.triggered
                        ? "rgb(235 210 210)"
                        : "rgb(194, 223, 194)",
                    }}
                  >
                    <i
                      className="material-icons"
                      style={{
                        color: event.triggered ? "maroon" : "forestgreen",
                        marginRight: "0px",
                      }}
                    >
                      {event.triggered ? "circle_notifications" : "task_alt"}
                    </i>
                    <b className="show-for-large-only">
                      {event.triggered ? "Alert" : " OK  "}
                    </b>
                  </div>
                  {/* <div>
                    {event.triggered && event.numTicksBeyond &&
                      <span style={{ color: "gray", fontSize: "0.8em" }}>
                        {event.numTicksBeyond} ticks beyond
                      </span>
                    }
                  </div> */}
                </div>
                <div style={{ flex: columnWidths[3], display: 'flex', alignItems: 'flex-start' }}>
                  <span style={{ display: 'flex', flexDirection: 'column', }}>
                    <span>
                      {(event.params || [])[0] || "-"} {firstParamUnit}
                    </span>
                    <span style={{ fontSize: "11px"}}>
                      {firstParamTitle}
                    </span>
                  </span>
                </div>
                <div style={{ flex: columnWidths[4], display: 'flex', alignItems: 'flex-start' }}>
                  

                  <span style={{ display: 'flex', flexDirection: 'column', }}>
                    <span>
                      {(event.params || [])[1] || "-"} {secondParamUnit}
                    </span>
                    <span style={{ fontSize: "11px"}}>
                      {secondParamTitle}
                    </span>
                  </span>
                </div>
                <div style={{ flex: columnWidths[5], display: 'flex', alignItems: 'flex-start' }}>
                 
                  <span style={{ display: 'flex', flexDirection: 'column', }}>
                    <span>
                      {(event.params || [])[2] || "-"} {thirdParamUnit}
                    </span>
                    <span style={{ fontSize: "11px"}}>
                      {thirdParamTitle}
                    </span>
                  </span>
                </div>
                {/* <div style={{ flex: columnWidths[6], whiteSpace: "pre-line" }}>{event.actions.map(a => a).join(',\n')}</div> */}
                <div style={{ flex: columnWidths[7] }}>
                  <Link
                    to={`/devices/connected/${device.enclosureSerialNumber}`}
                  >
                    <span>
                      {device.deviceName === "SynLink PDU" || !device.deviceName
                        ? `PDU SN:${device.enclosureSerialNumber}`
                        : device.deviceName}
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

const Loading = ({ columnWidths }) => {
  return (
    <div style={{}}>
      {[null, null, null, null].map((item, index) => {
        return (
          <div className="grid-row-wrapper" key={index}>
            <div className="grid-row">
              {/* <div style={{ flex: columnWidths[0] }}></div> */}
              <div style={{ flex: columnWidths[1] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[2] }}>
                <div className="skeleton-blank" />{" "}
              </div>
              <div style={{ flex: columnWidths[3] }}>
                <div className="skeleton-blank" />{" "}
              </div>
              <div style={{ flex: columnWidths[4] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[5] }}>
                <div className="skeleton-blank" />
              </div>
              {/* <div style={{ flex: columnWidths[6] }}>
                <div className="skeleton-blank" />{" "}
              </div> */}
              <div style={{ flex: columnWidths[7] }}>
                <div className="skeleton-blank" />
              </div>
              {/* <div style={{ flex: columnWidths[8] }}>
              <div className="skeleton-blank" />
            </div> */}
              {/* <div style={{ flex: columnWidths[9] }}>
                <div className="skeleton-blank" />{" "}
              </div> */}
            </div>
          </div>
        );
      })}
    </div>
  );
};
