import React from "react";
import { format } from 'date-fns';


import ModalManager from "../../components/common/modal/ModalManager";
import SelectInput from "../../components/common/inputs/selectInput";
import TextInput from "../../components/common/inputs/textInput";
import { formatDistanceStrict } from "date-fns";
import { Link } from "react-router-dom";
import NotificationManager from '../../utils/notificationManager';
import Pagination from '../../components/common/pagination';
// import Loading from '../../components/common/loading';
function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default class DeviceOutlets extends React.Component {
  state = {
    selectedOutlets: [],
    selectedGroup: "",
    selectedGroupId: "",
    editingActive: false,
    batchOperationLoading: false,
    outletDelay: 3,
    loadingOutlets: [],
    pendingOutletUuids: [],
    numFinished: 0,

    searchBar: "",
    currentFilter: "",

    chartzViewType: "outletCurrentView", // outletSwitchingView
    // pendingSetTimeouts: [],
  };
  applyFilters = () => {
    this.setState({
      currentFilter: this.state.searchBar
    })
  }

  componentDidMount() {
    if (!this.props.resources.groupsLoaded && !this.props.resources.groupsLoading) {
      this.props.fetchResource("groups");
    }
    if (!this.props.resources.outletsLoaded && !this.props.resources.outletsLoading) {
      this.props.fetchResource("outlets");
    }
    
    if (
      !this.props.resources.activityLoaded &&
      !this.props.resources.activityLoading
    ) {
      if (this.props.resources.activityLoaded.length > 0) {
      } else {
        // this.setState({
        //   activityLoading: true,
        // });
        this.props.fetchResource("activity");
      }
    }
    if (this.props.resources.outletsLoaded) {
      const allOutletsAreSwitchedOnly = this.props.resources.outlets.filter(o => o.currentRms === undefined).length === this.props.resources.outlets.length;
      if (allOutletsAreSwitchedOnly) {
        this.setState({
          chartzViewType: "outletSwitchingView"
        })
      } 
    }

  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.resources.outletsLoaded && !prevProps.resources.outletsLoaded) {
      const allOutletsAreSwitchedOnly = this.props.resources.outlets.filter(o => o.currentRms === undefined).length === this.props.resources.outlets.length;
      if (allOutletsAreSwitchedOnly) {
        this.setState({
          chartzViewType: "outletSwitchingView"
        })
      } 
    }
  }
  setActiveGroup = (groupId) => {
    const foundGroup =
      this.props.resources.groups.find((g) => g.id === groupId) || {};
    // const foundDeviceIds = (foundGroup.items || []).map((i) => ({
    //   enclosureSerialNumber: parseInt(i, 10),
    // }));
    this.setState({
      selectedOutlets: foundGroup.items || [],
      selectedGroup: foundGroup.name,
      selectedGroupId: foundGroup.id,
      outletDelay: foundGroup.outletBatchDelay || 3,
    });
  };

  editOutletName = (outlet) => {
    ModalManager.confirm(
      "",
      `Outlet ${outlet.outlet_id}`,
      "",
      (submitted, close) => {
        if (submitted) {
          close();
        }
      },
      ({ close }) => (
        <EditOutletForm
          modifyResource={this.props.modifyResource}
          outlet={outlet}
          close={close}
        />
      ),
      true,
      true
    );
  };
  createGroup = () => {
    ModalManager.confirm(
      "",
      "Create New Group",
      "Create",
      (status, close) => {
        if (status) {
          close();
        }
      },
      ({ close }) => (
        <InletGroupForm
          close={close}
          items={this.state.selectedOutlets}
          createResource={this.props.createResource}
          changeAppState={(newState) => this.setState(newState)}
        />
      ), // body component
      true,
      true
    );
  };
  updateGroup = (groupId) => {
    this.props.modifyResource(
      "groups",
      groupId,
      {
        items: this.state.selectedOutlets,
        outletBatchDelay: parseInt(this.state.outletDelay, 10),
      },
      (err, res) => {
        if (!err && res) {
          this.setState({ editingActive: false });
        } else {
          console.log("err occurred");
        }
      }
    );
  };
  updateOutlet = (outletId, status, cb = () => {}, idField, noNotification) => {
    this.setState({
      loadingOutlets: [...this.state.loadingOutlets, outletId],
    });
    this.props.modifyResource("outlets", outletId, status, (err, res) => {
      this.setState({
        loadingOutlets: this.state.loadingOutlets.filter((o) => o !== outletId),
      });
      cb(err, res);
      if (!err && res) {
        // console.log("hurray");
        try {
          if (status.state === "REBOOT") {
            const foundOutlet = this.props.resources.outlets.find(o => o.outlet_id == outletId);
              const rebootTime = foundOutlet?.rebootTime;
              // make a fetch call to re-get the outlet or device one second after the reboot time, if no reboot time default to 5 seconds
              const fetchTime = rebootTime ? rebootTime+1 : 5; // seconcs
              setTimeout(() => {
                // this.props.fetchResource("outlets");
                // this.props.fetchResource("devices");
                if (foundOutlet) {
                  this.props.getDeviceItemAndData(foundOutlet.enclosureSerialNumber, () =>{}, false);
      
                } else {
                  console.error("Could not update outlet information, missing serial number")
                }
                
      
              }, fetchTime*1000); // setTimeout accepts ms
          }

        } catch (error) {
          console.log(error)
        }

      } else {
        // console.log("wah");
      }
    }, idField, noNotification);
  };
  removeGroup = (groupId) => {
    ModalManager.confirm(
      "This action will permanently delete this group.",
      "Remove Group",
      "Remove",
      (submitted, close) => {
        if (submitted) {
          this.props.removeResource("groups", groupId, (err, res) => {
            this.setState({
              selectedOutlets: [],
              selectedGroup: "",
              selectedGroupId: "",
              editingActive: false,
              outletDelay: 3,
            });
            close();
          });
        } else {
          // close();
        }
      }
    );
  };
  batchOperation = async (value) => {
    if (this.state.batchOperationLoading) {
      return;
    }
    const outlets = this.state.selectedOutlets.filter((o) =>
      this.props.resources.outlets.find((oo) => oo.id === o)
    );

    const updateOutletPromise = outletId => new Promise((resolve, reject) => {
      this.updateOutlet(outletId, { state: value }, (err, data) => {
        if (err) {
          reject(err)
        } else {
          resolve(data)
        }
      }, "id", true)
    })

    this.setState({
      batchOperationLoading: true,
      pendingOutletUuids: outlets,  
      numFinished: 0
    });
    
    var loadingOutlets = this.state.loadingOutlets;
    NotificationManager.info(`Batch operation started for ${this.state.selectedGroup}`);

    for (var i = 0; i < outlets.length; i++) {
        if (this.state.numFinished < outlets.length) {
          loadingOutlets.push(outlets[i]);
          this.setState({ loadingOutlets})

          // NotificationManager.info(`Setting outlet ${outlets[i]} to ${value}`);
          await timeout(this.state.outletDelay * 1000);
          await updateOutletPromise(outlets[i]);
          loadingOutlets.splice(loadingOutlets.indexOf(outlets[i]), 1);
          this.setState({ loadingOutlets})
          this.setState({numFinished: this.state.numFinished + 1});
        } else {
          console.log("not yet");
        }
    }
    NotificationManager.info(`Batch operation finished for ${this.state.selectedGroup}`);
    this.setState({
      batchOperationLoading: false,
      pendingOutletUuids: [],
      numFinished: 0,
      loadingOutlets: [],
    })

  };
  cancelBatchOperation = () => {
    this.setState({
      numFinished: this.state.selectedOutlets.length,
      batchOperationLoading: false,
      // pendingOutletUuids: [],
      // loadingOutlets: [],
    });
  };
  render() {
    // console.log(this.state.loadingOutlets);
    const allOutlets =
      this.state.selectedGroup && !this.state.editingActive
        ? this.props.resources.outlets.filter((o) =>
            this.state.selectedOutlets.find((reff) => reff === o.id)
          )
        : this.props.resources.outlets;
      const deviceOutletFinder = (enclosureSn) => this.props.resources.devices.find(d => d.enclosureSerialNumber == enclosureSn);
    const outlets = allOutlets.filter(o => `${o.id} ${o.outletName || ""} ${o.enclosureSerialNumber} ${deviceOutletFinder(o.enclosureSerialNumber)?.deviceName || ""}`.toLowerCase().includes(this.state.currentFilter.toLowerCase()))
    const columnWidths = [
      1, //0 checkbox
      1, //1 outlet#
      7, //2 outlet name
      6, //3 PDU
      2, //4 outlet state
      5, //5 outlet current
      2, //6 outlet power
      3, //7 outlet voltage
      6, //8 operation
      2, //9 updated
      1, //10 refresh
    ];
    const groups = this.props.resources.groups.filter(
      (g) => g.groupType === "outlets"
    );
    const currentGroup =
      groups.find((g) => g.id === this.state.selectedGroupId) || {};
    const editingGroupHasChanges = (() => {
      if (!this.state.editingActive) {
        return false;
      } else {
        return (
          this.state.selectedOutlets.join("") !==
            (currentGroup.items || []).join("") ||
          this.state.outletDelay !== currentGroup.outletBatchDelay
        );
      }
    })();
    const totalCurrentRms = this.props.resources.outlets.reduce(
      (sum, item) => sum + (item.currentRms || 0),
      0
    );
    const averageCurrentRms =
      totalCurrentRms /
      this.props.resources.outlets.filter(
        (o) => o.connectionState === "Connected"
      ).length;
    const highestCurrentRms =
      this.props.resources.outlets.length > 0
        ? Math.max(
            ...this.props.resources.outlets.map((o) => o.currentRms || 0)
          )
        : 0;
    const numUnused = this.props.resources.outlets.filter(
      (o) => o.currentRms === 0
    ).length;
    const allOutletsAreSwitchedOnly = this.props.resources.outlets.filter(o => o.currentRms === undefined).length === this.props.resources.outlets.length;
    const noOutletsWithPowerMeasurements = this.props.resources.outlets.filter(o => o.currentRms === undefined).length === this.props.resources.outlets.length;
    const role = this.props.user.role
    // const outletEditAllowed = !role || role.editAllOutletsAllowed
    const outletsSwitchesAllowed = (() => {
      if (!role) {
        return true;
      } else {
        if (role.switchAllOutletsAllowed) {
          return true;
        } else {
          return false;
        }
      }
    })()
    return (
      <div
        style={
          {
            // overflowX: "auto",
          }
        }
      >
        {(!this.props.hideChartz && !allOutletsAreSwitchedOnly)
         &&

        <div className="grid-x grid-padding-x">

          <div
          className="cell"
            style={{
              display: 'flex',
              justifyContent: "flex-end",
              alignItems: 'center'
            }}
          >
            <select
              onChange={e => {
                this.setState({ chartzViewType: e.target.value })
              }}
              style={{
                margin: "0px",
                width: "fit-content",
                border: "none",
                backgroundColor: "transparent",
                boxShadow: "none"
              }}
            >
              <option value="outletCurrentView">Outlet Measurement Stats</option>
              <option value="outletSwitchingView">Outlet Switching Info</option>
            </select>
          </div>
          
          </div>
        }
        {!this.props.hideChartz &&
          <Chartz
            loading={this.props.resources.outletsLoading}
            sections={
              // all outlets read current RMS show following
              this.state.chartzViewType === "outletCurrentView" ? 
              [
              {
                title: "Total Current RMS",
                value: `${(totalCurrentRms || 0).toFixed(2)} A`,
                description: "All outlets with measurement capabilities summed",
              },
              {
                title: "Average Current RMS",
                value: `${(averageCurrentRms || 0).toFixed(2)} A`,
                description:
                  "Average of all outlets with measurement capabilitties",
              },
              {
                title: "Highest Current Outlet",
                value: `${(highestCurrentRms || 0).toFixed(2)} A`,
                description: "Individual highest outlet current",
              },
              {
                title: "Unused Outlets",
                value: `${numUnused} Outlets`,
                description: "Number of outlets with current RMS draw at 0 Amps",
                // value: `${numOnOutlets} ON, ${numOffOutlets} OFF`,
              },
            ] :
            this.state.chartzViewType === "outletSwitchingView" ? 
            [{
              title: "Number of Switchable Outlets",
              value: `${this.props.resources.outlets.filter(o => o.state).length} Outlets`,
              description: 
                "Number of outlets capable of switching outlets that are online and accessible",
            // }, {
            //   title: "Number Outlets Switched ON",
            //   value: `${this.props.resources.devices.filter(d => (d.connectionState === "Connected") && d.outletSwitchingSupported).length}`,
            //   description: "Number of PDUs online with switchable outlets"
            }, {
              title: "Number of Outlets Switched Off",
              value: `${this.props.resources.outlets.filter(o => o.state === "OFF" ).length}`,
              description: `${this.props.resources.outlets.filter(o => o.state === "OFF" ).length} out of a total of ${this.props.resources.outlets.filter(o => o.connectionState === "Connected").length} outlets switched OFF`
            }, {
              title: "unique_id",
              component: () => {
                return (
                  <div className="cell large-6 medium-12">
                    <div
                      style={{
                        flex: 1,
                        borderRadius: "3px",
                        display: "flex",
                        alignItems: "flex-start",
                        paddingLeft: "20px",
                        justifyContent: "flex-start",
                        height: "100%",
                        boxShadow: "2px 2px 5px rgba(0,0,0,0.14)",
                      }}
                      className="app-item"
                    >
                      <div style={{ width: "100%" }}>
                        <div
                          style={{
                            paddingBottom: "10px",
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: "100%"
                          }}
                        >
                          <Link
                            to={`/activity/history?action=outlet_switched,outlet_edited${this.props.device ? `&enclosureSN=${this.props.device.enclosureSerialNumber}` : ""}`}
                          >
                            <span
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                color: "#333",
                                cursor: 'pointer',
                              }}
                            >
                              Outlet Activity Log
                              <i className="material-icons">chevron_right</i>
                            </span>
                          </Link>
                          {(this.props.resources.activityLoading && this.props.resources.activityLoaded) ? 
                          <Loading black/> :
                          <span
                            style={{
                              // color: "white",
                              // margin: "0px",
                              // padding: "5px 10px"
                              color: "rgb(108,111,114)",
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              this.props.fetchResource("activity");
                            }}
                          >
                            <i className="material-icons" style={{ fontWeight: "600" }}>sync</i>
                          </span>

                          }
                        </div>
                        {(this.props.resources.activityLoading && !this.props.resources.activityLoaded) && <Loading black />}
                        {this.props.resources.activity.filter(a => {
                          if (a.action === "outlet_switched") {
                            if (this.props.resources.outlets.find(o => o.id === a.resourceId)) {
                              return true
                            } else {
                              return false
                            }
                          } else {
                            return false;
                          }
                        }).slice(0,5).map(a => {
                          return (
                            <div
                              key={a.createdAt}
                              style={{
                                color: "#666",
                                fontSize: "87%",
                                display: 'flex',
                                borderBottom: "solid #eee 1px",
                                paddingBottom: "3px",
                                marginBottom: "3px"
                              }}
                            >
                              <span style={{
                                // border: "solid red 1px",
                                whiteSpace: "pre"
                              }}>{format(new Date(a.createdAt), "MMM d, HH:mm")}: </span> 
                              <span style={{
                                // border: "solid blue 1px"
                              }}>{a.details}</span> 
                            </div>
                          )
                        })}
                        {/* <span
                          style={{
                            fontSize: "87%"
                          }}
                        >
                          See More
                        </span> */}
                      </div>
                    </div>
                  </div>
                )
              }
            }] : 
            []
          }
          />
        
        }
        <div
          style={{
            borderBottom: "solid 1px #dedede",
            // marginLeft: "15px",
            // marginRight: "15px",
            margin: "20px 0px 15px 30px"
          }}
        />
        <div className="grid-x grid-padding-x">
          <div className="cell large-6 medium-6 small-12">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="text"
                  placeholder="Search for outlet name, device name, or serial number"
                  value={this.state.searchBar}
                  onChange={(e) => {
                    this.setState({ searchBar: e.target.value });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      this.applyFilters();
                    }
                  }}
                />
                <span
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    padding: "0.45rem",
                    height: "2.43rem",
                    margin: "0 0 1rem",
                    borderRadius: "0.1875rem",
                    // backgroundColor: "#49535E",
                    // color: "#fff",
                  }}
                  className="background-color-secondary"

                  onClick={() => {
                    this.applyFilters();
                  }}
                >
                  <i className="material-icons" style={{ marginRight: "0px"}}>search</i>
                </span>
              </div>
            </div>
        </div>
        <div
          style={{
            // border: "solid red 1px",
            overflowX: "auto",
            // maxHeight: "80vh",
          }}
        >
          <div className="grid-row header" style={{ position: "sticky" }}>
            <div style={{ flex: columnWidths[1] }}>Outlet #</div>
            <div style={{ flex: columnWidths[2] }}>Outlet Name</div>
            <div style={{ flex: columnWidths[3] }}>PDU</div>
            <div style={{ flex: columnWidths[4] }}>State</div>
            {!noOutletsWithPowerMeasurements &&
              <>
                <div style={{ flex: columnWidths[5] }}>Current RMS</div>
                <div style={{ flex: columnWidths[6] }}>Power</div>
                <div style={{ flex: columnWidths[7] }}>Voltage</div>
              </>            
            }
            {outletsSwitchesAllowed &&
            <div style={{ flex: columnWidths[8] }}>Operation</div>
            }
            <div style={{ flex: columnWidths[9] }}>Last Updated</div>
            <div style={{ flex: columnWidths[10] }}></div>
          </div>

          {this.props.resources.outletsLoading && this.props.resources.outlets.length === 0 &&  (
            <LoadingSection columnWidths={columnWidths} />
          )}

          {!this.props.resources.outletsLoading && outlets.length === 0 && (
            <React.Fragment>
              <div className="grid-row-wrapper">
                <div className="grid-row">
                  <div
                    style={{
                      paddingLeft: "50px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <div>No Outlets Found</div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  paddingTop: "40px",
                }}
              >
                <span className="button">
                  <a
                    href="https://synaccess.readme.io/reference/connect-a-synlink-pdu"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "white" }}
                  >
                    Learn how to connect a SynLink PDU
                  </a>
                </span>
              </div>
            </React.Fragment>
          )}
          {/* {outlets.map((outlet) => (
            <OutletTableRow
            resources={this.props.resources}
            noOutletsWithPowerMeasurements={noOutletsWithPowerMeasurements}
              outlet={outlet}
              outletsSwitchesAllowed={outletsSwitchesAllowed}
              columnWidths={columnWidths}
              selectedOutlets={this.state.selectedOutlets}
              devices={this.props.resources.devices}
              changeState={(newState) => this.setState(newState)}
              updateOutlet={this.updateOutlet}
              getDeviceItemAndData={this.props.getDeviceItemAndData}
              loadingOutlets={this.state.loadingOutlets}
              selectedGroupId={this.state.selectedGroupId}
              editingActive={this.state.editingActive}
              hideGroupInputs={this.props.hideGroupInputs}
              editOutletName={this.editOutletName}
              key={outlet.id}
            />
          ))} */}
          <Pagination
            items={outlets}
            renderItem={(outlet) => {
              return (
                <OutletTableRow
                  resources={this.props.resources}
                  noOutletsWithPowerMeasurements={noOutletsWithPowerMeasurements}
                  outlet={outlet}
                  outletsSwitchesAllowed={outletsSwitchesAllowed}
                  columnWidths={columnWidths}
                  selectedOutlets={this.state.selectedOutlets}
                  devices={this.props.resources.devices}
                  changeState={(newState) => this.setState(newState)}
                  updateOutlet={this.updateOutlet}
                  getDeviceItemAndData={this.props.getDeviceItemAndData}
                  loadingOutlets={this.state.loadingOutlets}
                  selectedGroupId={this.state.selectedGroupId}
                  editingActive={this.state.editingActive}
                  hideGroupInputs={this.props.hideGroupInputs}
                  editOutletName={this.editOutletName}
                  key={outlet.id}
                />
              )
            }}
          />
        </div>
      </div>
    );
  }
}

const DataValue = ({ value, unit }) => {
  return (
    <span style={{ display: "flex", alignItems: "flex-end" }}>
      <span
        style={{
          fontWeight: "600",
          fontSize: "105%",
        }}
      >
        {value}
      </span>
      <span
        style={{
          fontSize: "80%",
          paddingLeft: "4px",
          paddingBottom: "1px",
        }}
      >
        {unit}
      </span>
    </span>
  );
};
function getColor(value, opacity = 1) {
  //value from 0 to 1
  return value > 1
    ? "#9d0002" //red
    : value > 0.75
    ? "#b87000" //orange
    : value > 0.5
    ? "#CC9900" //yellow
    : value > 0.25
    ? "#7c9d00" // yellow green
    : "green";
}

const LoadingSection = ({ columnWidths }) => {
  return (
    <div style={{}}>
      {[null, null, null, null].map((item, index) => {
        return (
          <div className="grid-row-wrapper" key={index}>
            <div className="grid-row">
              <div style={{ flex: columnWidths[0] }}></div>
              <div style={{ flex: columnWidths[1] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[2] }}>
                <div className="skeleton-blank" />{" "}
              </div>
              <div style={{ flex: columnWidths[3] }}>
                <div className="skeleton-blank" />{" "}
              </div>
              <div style={{ flex: columnWidths[4] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[5] }}>
                <div className="skeleton-blank" />
              </div>
              <div style={{ flex: columnWidths[6] }}>
                <div className="skeleton-blank" />{" "}
              </div>
              <div style={{ flex: columnWidths[7] }}>
                <div className="skeleton-blank" />
              </div>
              {/* <div style={{ flex: columnWidths[8] }}>
                <div className="skeleton-blank" />
              </div> */}
              <div style={{ flex: columnWidths[9] }}>
                <div className="skeleton-blank" />{" "}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
class InletGroupForm extends React.Component {
  state = {
    groupName: "",
  };
  submitGroup = () => {
    this.props.createResource(
      "groups",
      {
        name: this.state.groupName,
        groupType: "outlets",
        items: this.props.items,
      },
      (err, newGroup) => {
        if (newGroup) {
          this.props.changeAppState({
            selectedGroup: this.state.groupName,
            selectedGroupId: newGroup.id,
          });
          this.props.close();
        } else {
          console.log(err, newGroup);
        }
      }
    );
  };
  render() {
    return (
      <div>
        <TextInput
          label="Group Name"
          callback={(value) => {
            this.setState({ groupName: value });
          }}
          value={this.state.groupName}
          type="text"
          error=""
          placeholder="Example Name"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "30px",
            paddingBottom: "-30px",
          }}
        >
          <div
            className="button"
            style={{
              backgroundColor: "rgba(0,0,0,0.4)",
            }}
            onClick={() => this.props.close()}
          >
            Cancel
          </div>
          <div
            className="button"
            onClick={() => {
              this.submitGroup();
            }}
          >
            Submit
          </div>
        </div>
      </div>
    );
  }
}

const GroupInputs = ({
  updateState,
  setActiveGroup,

  editingGroupHasChanges,
  editingActive,

  groups,
  createGroup,
  removeGroup,
  updateGroup,
  currentGroup,

  selectedGroup,
  selectedGroupId,
  selectedOutlets,

  batchOperation,
  batchOperationLoading,

  outletDelay,
  // devices,
  pendingOutletUuids,
  numFinished,
  loadingOutlets,
  cancelBatchOperation,
}) => {
  return (
    <div
      style={{
        marginBottom: "0px",
      }}
      className="grid-x grid-padding-y grid-padding-x"
    >
      <div
        className="cell large-auto"
        style={{
          border: "solid transparent 1px",
        }}
      >
        <SelectInput
          label=""
          callback={(result) => {
            if (!result) {
              setActiveGroup("");
            } else {
              setActiveGroup(parseInt(result, 10));
            }
          }}
          value={selectedGroupId}
          options={groups.map((g) => ({
            label: g.name,
            value: g.id,
          }))}
          inputContainerStyle={{
            margin: "0px",
          }}
          inputStyle={{
            backgroundColor: "white",
            fontWeight: "600",
            // minWidth: "250px",
            // maxWidth: "300px"
          }}
          defaultValue="Select A Group"
        />
      </div>

      {selectedOutlets.length > 0 && !editingActive && !selectedGroup && (
        <div // create group button
          style={{
            // display: 'flex',
            // border: "solid red 1px"
            border: "solid transparent 1px",
          }}
          className="cell large-3"
        >
          <div
            className="button"
            style={{
              margin: "0px",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              createGroup();
            }}
          >
            Create New Group ({selectedOutlets.length} selected)
          </div>
        </div>
      )}
      <div className="cell ">
        {editingActive && (
          <div // edit group buttons
            style={{
              display: "flex",
              // border: "solid green 1px"
            }}
            className="grid-x grid-padding-x grid-padding-y"
          >
            <div
              className=" cell"
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                <div
                  className="button"
                  style={{
                    backgroundColor: "rgba(0,0,0,0.1)",
                    color: "rgba(0,0,0,0.9)",
                    marginBottom: "0px",
                    marginRight: "5px",
                  }}
                  onClick={() => {
                    updateState({ editingActive: false });
                    // setActiveGroup(parseInt(currentGroup.id, 10));
                  }}
                >
                  Cancel Edit
                </div>

                <div
                  className="button"
                  style={{
                    backgroundColor: "rgb(140, 56, 56)",
                    marginBottom: "0px",
                  }}
                  onClick={() => removeGroup(selectedGroupId)}
                >
                  Remove Group
                </div>
              </div>
              {editingGroupHasChanges && (
                <div
                  className="button"
                  style={{
                    // backgroundColor: '#49535E',
                    backgroundColor: "royalblue",
                    // color: 'rgba(0,0,0,0.9)',
                    color: "white",
                    // marginLeft: "5px",
                    marginBottom: "0px",
                  }}
                  onClick={() => {
                    updateGroup(selectedGroupId);
                  }}
                >
                  Save Changes
                </div>
              )}
            </div>

            <div
              className=" cell"
              style={{
                display: "flex",
                // justifyContent: 'space-between'
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  // marginLeft: '5px'
                }}
              >
                <div
                  style={{
                    // marginRight: '5px',
                    backgroundColor: "#49535D",
                    color: "white",
                    height: "2.4375rem",
                    borderRadius: "4px 0px 0px 4px",
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 10px",
                    minWidth: "150px",
                  }}
                  title="Number of seconds between each switch when change multiple outlet states in one command"
                >
                  Batch Switch Delay
                </div>
                <input
                  style={{
                    width: "100px",
                    borderRadius: "0px 4px 4px 0px",
                    marginBottom: "0px",
                  }}
                  type="number"
                  min="0"
                  value={outletDelay}
                  onChange={(e) => updateState({ outletDelay: e.target.value })}
                />
              </div>
            </div>
          </div>
        )}
        {selectedGroup && !editingActive && (
          <div // selected group buttons
            style={
              {
                // display: 'flex',
                // justifyContent: 'space-between',
                // flexWrap: 'wrap'
                // border: "solid blue 1px"
              }
            }
            className="grid-x grid-padding-y"
          >
            <div className="cell large-6 large-order-1 medium-order-2 small-order-2">
              <div
                className="button"
                style={{
                  backgroundColor: "rgba(0,0,0,0.1)",
                  color: "rgba(0,0,0,0.9)",
                  marginBottom: "0px",
                  marginRight: "5px",
                }}
                onClick={() => {
                  updateState({
                    selectedGroup: "",
                    selectedGroupId: "",
                    selectedOutlets: [],
                    editingActive: false,
                  });
                }}
              >
                Unselect Group
              </div>
              <div
                className="button"
                style={{
                  // backgroundColor: '#49535E',
                  backgroundColor: "#49535E",
                  // color: 'rgba(0,0,0,0.9)',
                  color: "white",
                  // marginLeft: "5px",
                  marginBottom: "0px",
                  marginRight: "5px",
                }}
                onClick={() => {
                  updateState({
                    editingActive: true,
                  });
                }}
              >
                Edit Group
              </div>
            </div>
            <div
              className="cell large-6 large-order-2 medium-order-1 small-order-1"
              style={
                {
                  // display: 'flex',
                  // justifyContent:"flex-end"
                }
              }
            >
              {batchOperationLoading ? (
                <div
                  style={{
                    display: "flex",
                    // border: "solid red 1px",
                    // justifyContent: 'flex-end'
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="button"
                    style={{
                      // backgroundColor: '#49535E',
                      backgroundColor: "maroon",
                      // color: 'rgba(0,0,0,0.9)',
                      color: "white",
                      marginLeft: "5px",
                      marginBottom: "0px",
                    }}
                    onClick={() => {
                      cancelBatchOperation();
                    }}
                  >
                    Cancel Batch Operation
                  </div>

                  <div
                    style={
                      {
                        // width: '240px',
                      }
                    }
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        // backgroundColor: "white",
                        // padding: "10px 30px",
                        // border: "solid rgba(0,0,0,0.1) 1px",
                        borderRadius: "3px",
                        marginLeft: "3px",
                        paddingRight: "8px",
                        // border: "solid red 1px"
                      }}
                    >
                      <Loading black />
                      <b>
                        Batch Operation ({numFinished}/
                        {pendingOutletUuids.length})
                      </b>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={
                    {
                      // width: '240px',
                    }
                  }
                >
                  <SelectInput
                    inputContainerStyle={{
                      margin: "0px",
                      backgroundColor: "white",
                    }}
                    options={[
                      {
                        label: "Turn On Selected Outlets",
                        value: "ON",
                      },
                      {
                        label: "Turn Off Selected Outlets",
                        value: "OFF",
                      },
                      {
                        label: "Power Cycle Selected Outlets",
                        value: "REBOOT",
                      },
                    ]}
                    value=""
                    callback={(value) => {
                      batchOperation(value);
                    }}
                    defaultValue="Select Operation"
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const Chartz = ({
  sections = [
    {
      value: "",
      title: "",
      description: "",
    },
  ],
  // totalCurrentRms,
  // averageCurrentRms,
  // totalCurrentRms,
  // // allInlets,
  // totalWattage,
  loading,
}) => {
  return (
    <div
      className="grid-x grid-padding-x grid-padding-y"
      style={{ marginTop: "0px" }}
    >
      {sections.map((section) => {
        if (section.component) {
          const TempComponent = section.component;
          return <TempComponent />
          // return {section.component}
        }
        return (
          <div key={section.title} className="cell large-3 medium-6">
            <div
              style={{
                flex: 1,
                borderRadius: "3px",
                display: "flex",
                alignItems: "flex-start",
                paddingLeft: "20px",
                justifyContent: "flex-start",
                // textAlign: "flex-start",
                height: "100%",
                boxShadow: "2px 2px 5px rgba(0,0,0,0.14)",
              }}
              className="app-item"
            >
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "120px",
                  }}
                >
                  <Loading black />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <div>{section.title}</div>
                  <div
                    style={{
                      fontSize: "150%",
                      fontWeight: "600",
                      padding: "12px 0px 7px 0px",
                    }}
                  >
                    {section.value}
                  </div>
                  <div
                    style={{
                      color: "grey",
                      fontSize: "85%",
                    }}
                  >
                    {section.description}
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

class OutletTableRow extends React.Component {
  render() {
    const {
      outlet,
      columnWidths,
      selectedOutlets,
      devices,
      changeState,
      updateOutlet,
      getDeviceItemAndData,
      loadingOutlets,
      editingActive,
      selectedGroupId,
      hideGroupInputs,
      outletsSwitchesAllowed,
      noOutletsWithPowerMeasurements
    } = this.props;

    const maxOutletReceptacle =
      {
        "IEC 60309 C13R": 12,
        "IEC 60309 C19R": 16,
        "NEMA 5-20R": 16,
      }[outlet.receptacle] || 16;

    const outletIsSelected = !!selectedOutlets.find((o) => o === outlet.id);
    const device =
      devices.find(
        (d) => d.enclosureSerialNumber === outlet.enclosureSerialNumber
      ) || {};

    const loading = loadingOutlets.find((o) => o === outlet.id);
    return (
      <div
        key={outlet.id}
        className={`grid-row-wrapper ${
          device.connectionState === "Connected" ? "" : "disconnected"
        }`}
        title={device.connectionState === "Connected" ? "" : "Disconnected"}
      >
        <div
          className={`grid-row ${
            device.connectionState === "Connected"
              ? "connected"
              : "disconnected"
          }`}
          style={{
            marginBottom: "4px",
          }}
        >
          {/* <div style={{ flex: columnWidths[0] }}>
            {hideGroupInputs ? null : (
              <input
                type="checkbox"
                checked={outletIsSelected}
                onChange={() => {
                  if (selectedGroupId && !editingActive) {
                    return; // don't allow check box clicking when a group is selected, and editing is deactivated
                  }
                  if (outletIsSelected) {
                    changeState({
                      selectedOutlets: selectedOutlets.filter(
                        (o) => o !== outlet.id
                      ),
                    });
                  } else {
                    changeState({
                      selectedOutlets: [...selectedOutlets, outlet.id],
                    });
                  }
                }}
                style={{
                  boxShadow: "",
                  margin: "0px",
                  height: "1.4rem",
                }}
              />
            )}
          </div> */}
          <div style={{ flex: columnWidths[1] }}>
            <Link
              to={`/devices/connected/${device.enclosureSerialNumber}/outlets/${outlet.id}`}
            >
              {outlet.pduLabeledOutletNum}
            </Link>
          </div>
          <div style={{ flex: columnWidths[2] }}>
            <Link
              to={`/devices/connected/${device.enclosureSerialNumber}/outlets/${outlet.id}`}
            >
              <span style={{ textDecoration: "underline" }}>{outlet.outletName}</span>
            </Link>
            <span
              className="hover-show"
              style={{
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                marginLeft: "8px"
              }}
              onClick={() => {
                this.props.editOutletName(outlet);
              }}
            >
              {/* <span>{outlet.outletName}</span> */}
              <span className="hover-item">
                <i className="material-icons">edit</i>
              </span>
            </span>
          </div>
          <div style={{ flex: columnWidths[3] }}>
            {this.props.resources.devicesLoading ? (
              <div className="skeleton-blank slim" />
            ) : (
              <Link to={`/devices/connected/${device.enclosureSerialNumber}`}>
                {(device.deviceName === "SynLink PDU" || !device.deviceName)
                  ? `PDU SN:${device.enclosureSerialNumber}`
                  : device.deviceName}
              </Link>
            )}
          </div>

          <div style={{ flex: columnWidths[4] }}>
            {device.loading ? (
              <div className="skeleton-blank slim" />
            ) : outlet.state !== undefined ? (
              <span
                style={{
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <span
                  style={{
                    color:
                      outlet.state === "ON"
                        ? "forestgreen"
                        : outlet.state === "OFF"
                        ? "maroon"
                        : "",
                    fontWeight: "600",
                    display: "flex",
                  }}
                >
                  {(outlet.state || "").toUpperCase()}
                </span>
                <span
                  style={{
                    color:
                      outlet.rebootStatus === "pendingOff"
                        ? "maroon"
                        : outlet.rebootStatus === "pendingOn"
                        ? "forestgreen"
                        : "",
                  }}
                >
                  {outlet.rebootStatus === "pendingOn"
                    ? "(Pending On)"
                    : outlet.rebootStatus === "pendingOff"
                    ? "(Pending Off)"
                    : ""}
                </span>
              </span>
            ) : (
              "-"
            )}
          </div>
          {!noOutletsWithPowerMeasurements &&
          
            <>
              <div
                style={{ flex: columnWidths[5] }}
                title={`${outlet.receptacle} Receptacle`}
              >
                {device.loading ? (
                  <div className="skeleton-blank slim" />
                ) : outlet.currentRms !== undefined ? (
                  <span
                    style={{
                      display: "flex",
                    }}
                  >
                    <span
                      style={{
                        width: "max-content",
                        alignItems: "center",
                        display: "flex",
                        paddingRight: "5px",
                        paddingLeft: "5px",
                        color:
                          outlet.state !== "ON"
                            ? `#875b5b`
                            : `${getColor(
                                outlet.currentRms / maxOutletReceptacle
                              )}`,
                      }}
                    >
                      <DataValue
                        value={(outlet.currentRms || 0).toFixed(2)}
                        unit="A"
                      />
                      <span style={{ padding: "0px 3px" }}>/</span>
                      <DataValue
                        value={maxOutletReceptacle.toFixed(2)}
                        unit="A Max"
                      />
                    </span>
                  </span>
                ) : (
                  "-"
                )}
              </div>
              <div style={{ flex: columnWidths[6] }}>
                {typeof outlet.power === "number" ? (
                  device.loading ? (
                    <div className="skeleton-blank slim" />
                  ) : (
                    <DataValue value={(outlet.power || 0).toFixed(2)} unit="VA" />
                  )
                ) : (
                  "-"
                )}
              </div>
              <div style={{ flex: columnWidths[7] }}>
                {typeof outlet.circuitVoltage === "number" ? (
                  device.loading ? (
                    <div className="skeleton-blank slim" />
                  ) : (
                    <DataValue
                      value={(outlet.circuitVoltage || 0).toFixed(2)}
                      unit="V"
                    />
                  )
                ) : (
                  "-"
                )}
              </div>
            </>
          }
          {outletsSwitchesAllowed &&
          <div
            style={{
              flex: columnWidths[8],
              display: "flex",
              // flexWrap: "wrap",
            }}
          >
            {outlet.state === "OFF" && (
              <div
                className="spreadsheet-badge"
                style={{
                  backgroundColor: "rgb(194 223 194)",
                  marginRight: "3px",
                  padding: "3px 5px",
                  opacity: outlet.state === "OFF" ? "1" : "0.6",
                }}
                onClick={() => {
                  if (loading) {
                  } else {
                    updateOutlet(outlet.id, { state: "ON" });
                  }
                }}
                title="Switch Outlet from OFF state to ON state"
              >
                {loading ? (
                  <React.Fragment>
                    <Loading black />
                    <b>Loading</b>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <i
                      className="material-icons"
                      style={{ color: "forestgreen", marginRight: "0px" }}
                    >
                      offline_bolt
                    </i>
                    <b
                      className="show-for-largexx"
                      style={{ minWidth: "50px" }}
                    >
                      Turn On
                    </b>
                  </React.Fragment>
                )}
              </div>
            )}

            {outlet.state === "ON" && (
              <div
                className="spreadsheet-badge"
                style={{
                  backgroundColor: "rgb(235 210 210)",
                  marginRight: "3px",
                  padding: "3px 5px",
                  opacity: outlet.state === "OFF" ? "0.6" : "1",
                }}
                onClick={() => {
                  if (loading) {
                  } else {
                    updateOutlet(outlet.id, { state: "OFF" });
                  }
                }}
                title="Switch Outlet from ON state to OFF state"
              >
                {loading ? (
                  <React.Fragment>
                    <Loading black />
                    <b>Loading</b>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <i
                      className="material-icons"
                      style={{ color: "maroon", marginRight: "0px" }}
                    >
                      pause_circle
                    </i>
                    <b
                      className="show-for-largexx"
                      style={{ minWidth: "50px" }}
                    >
                      Turn Off
                    </b>
                  </React.Fragment>
                )}
              </div>
            )}
            {outlet.state && (
              <div
                className="spreadsheet-badge"
                style={{
                  backgroundColor: "#CCC",
                  // color: "white",
                  marginRight: "3px",
                  // padding: "3px 5px",
                  paddingLeft: "5px",
                  paddingTop: "3px",
                  paddingBottom: "3px",
                  paddingRight: "0px",
                  // opacity: outlet.state === "OFF" ? "0.6" : "1",
                }}
                onClick={() => {
                  if (loading) {
                  } else {
                    updateOutlet(outlet.id, { state: "REBOOT" });
                  }
                }}
                title="Reboot Outlet with outlet configured reboot time"
              >
                <i
                  className="material-icons"
                  style={{
                    // color: "maroon",
                    marginRight: "0px",
                  }}
                >
                  replay
                </i>
                <b
                  className="show-for-large"
                  style={{ minWidth: "50px", paddingLeft: "5px" }}
                >
                  Cycle
                </b>
              </div>
            )}
          </div>
          
          }
          <div style={{ flex: columnWidths[9] }}>
            <span
              style={{
                fontSize: "80%",
              }}
            >
              {device.lastUpdated &&
                `${formatDistanceStrict(device.lastUpdated, new Date())} ago`}
            </span>
          </div>
          <div style={{ flex: columnWidths[10] }}>
            <span
              title="Refresh PDU Information"
              className="black"
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                // console.log(device)
                getDeviceItemAndData(device.enclosureSerialNumber);
              }}
            >
              <i
                style={{ fontWeight: "600", color: "#6c6f72" }}
                className="material-icons"
              >
                sync
              </i>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const Loading = ({ size, black, fixed }) => (
  <div
    id={fixed ? "wrapper-fixed" : "wrapper"}
    style={{ padding: "3px", marginRight: "3px" }}
  >
    <div className="profile-main-loader" style={{ padding: "0px" }}>
      <div
        className="loader"
        style={{
          width: size || "20px",
          height: size || "20px",
          padding: "0px",
        }}
      >
        <svg className="circular-loader" viewBox="25 25 50 50">
          <circle
            className={black ? "loader-path-black" : "loader-path"}
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
          />
        </svg>
      </div>
    </div>
  </div>
);

class EditOutletForm extends React.Component {
  state = {
    outletName: "",
    loading: false,
  };
  componentDidMount() {
    this.setState({
      outletName: this.props.outlet.outletName,
    });
  }
  render() {
    const { outlet, close } = this.props;

    return (
      <div>
        <TextInput
          label="Outlet Name"
          value={this.state.outletName}
          callback={(txt) => {
            this.setState({ outletName: txt });
          }}
          placeholder="Enter Outlet Name"
        />
        <button
          className="button"
          onClick={() => {
            this.setState({ loading: true });
            this.props.modifyResource(
              "outlets",
              outlet.id,
              {
                outletName: this.state.outletName,
              },
              (err, res) => {
                this.setState({ loading: false });
                if (err) {
                  console.log(err);
                } else {
                  close();
                }
              },
              "id"
            );
          }}
        >
          {this.state.loading ? <Loading /> : "Change"}
        </button>
      </div>
    );
  }
}
