import React from "react";
import Loading from "../../components/common/loading";
// import icons from '../../../assets/icon-all';
import { Link } from "react-router-dom";
import ModalManager from "../../components/common/modal/ModalManager";
import TextInput from "../../components/common/inputs/textInput";
import Pagination from '../../components/common/pagination';

import { formatDistance } from "date-fns";
export default class DeviceConnected extends React.Component {
  componentDidMount() {}
  state = {
    bulkyViewActive: false,

    searchBar: "",
    currentFilter: "",
    connectionFilter: "",
    sortFilter: ""

  };
  updateDevice = (enclosureSerialNumber) => {
    this.props.getDeviceItemAndData(enclosureSerialNumber);
  };
  applyFilters = () => {
    this.setState({
      currentFilter: this.state.searchBar
    })
  }
  editDeviceName = (device) => {
    ModalManager.confirm(
      "",
      `${device.modelNumber} PDU (SN:${device.enclosureSerialNumber})`,
      "Edit",
      (submitted, close) => {
        if (submitted) {
          close();
        }
      },
      ({ close }) => (
        <EditDeviceForm
          modifyResource={this.props.modifyResource}
          device={device}
          close={close}
        />
      ),
      true,
      true
    );
  };
  render() {
    const {
      devices = [],
      devicesLoading,
      devicesLoaded,
      inletsLoading,
      outletsLoading,
      eventsLoading,
    } = this.props.resources;

    const {
      addGroupDeviceActive
    } = this.props;

    const columnWidths = [
      1, // connection icon
      3, // Model Number
      2, // Device Name
      7, // outlets
      2, // kwh
      3, // amps
      3, // alerts
      3, // serial
      6, // info
      2, // timestamp BUTTON
      1, // refresh
    ];

    const { 
      searchBar,
      currentFilter
    } = this.state;

    if (this.state.bulkyViewActive) {
      return (
        <div>
          {devices.map((device, y) => {
            return <DeviceLineItem key={y} {...device} />;
          })}
        </div>
      );
    }

    // const filteredDevices = devices.map(d => `${d.deviceName} ${d.enclosureSerialNumber} ${d.modelNumber}`).filter(d => d.toLowerCase().includes(searchBar.toLowerCase()));
    const filteredDevices = devices
      .filter(d=> `${d.deviceName} ${d.enclosureSerialNumber} ${d.modelNumber}`.toLowerCase().includes(currentFilter.toLowerCase()))
      .filter(d => {
        if (this.state.connectionFilter === "") {
          return true;
        } else {
          return d.connectionState === this.state.connectionFilter;
        }
      })
    let sortedFilteredDevices;
    const [sortKey, sortDirection] = this.state.sortFilter.split('_');
    if (this.state.sortFilter) {
      sortedFilteredDevices = filteredDevices.sort((a,b) => {
        if (sortKey === "ipAddress") {

          const foundConfA = this.props.resources.conf.find(conf => conf.enclosureSerialNumber == a.enclosureSerialNumber);
          const foundConfB = this.props.resources.conf.find(conf => conf.enclosureSerialNumber == b.enclosureSerialNumber);
          let ipAddrA;
          let ipAddrB;
          if (foundConfA) {
            if (foundConfA.ipAssign === "dhcp" && foundConfA.dhcpAssignedIp) {
              ipAddrA = foundConfA.dhcpAssignedIp;
            } else if (foundConfA.ipAssign === "static" && foundConfA.staticIp) {
              ipAddrA = foundConfA.staticIp;
            }
          } else {
            ipAddrA = '-';
          }
          if (foundConfB) {
            if (foundConfB.ipAssign === "dhcp" && foundConfB.dhcpAssignedIp) {
              ipAddrB = foundConfB.dhcpAssignedIp;
            } else if (foundConfB.ipAssign === "static" && foundConfB.staticIp) {
              ipAddrB = foundConfB.staticIp;
            }
          } else {
            ipAddrB = '-';
          }
          if (sortDirection === 'asc') {
            if (ipAddrA < ipAddrB) {
              return -1;
            }
            if (ipAddrA > ipAddrB) {
              return 1;
            }
            return 0;
          } else {
            if (ipAddrA > ipAddrB) {
              return -1;
            }
            if (ipAddrA < ipAddrB) {
              return 1;
            }
            return 0;
          }

          
        } else {

          if (sortDirection === 'asc') {
            if (a[sortKey] < b[sortKey]) {
              return -1;
            }
            if (a[sortKey] > b[sortKey]) {
              return 1;
            }
            return 0;
          } else {
            if (a[sortKey] > b[sortKey]) {
              return -1;
            }
            if (a[sortKey] < b[sortKey]) {
              return 1;
            }
            return 0;
          }
        }

      })
    } else {
      sortedFilteredDevices = filteredDevices;
    }
    // console.log(sortDirection)
    // console.log(sortDirection)
    const sortOptions = [
      { label: "Default", value: "" },
      // { label: `Device Name ${sortDirection === 'asc' ? '↑': '↓'}`, value: sortDirection === 'asc' ? "deviceName_desc" : "deviceName_asc" }, 
      // { label: `Serial Number ${sortDirection === 'asc' ? '↑': '↓'}`, value: sortDirection === 'asc' ? "enclosureSerialNumber_asc" : "enclosureSerialNumber_desc" },
      // { label: `IP Address ${sortDirection === 'asc' ? '↑': '↓'}`, value: sortDirection === 'asc' ? "ipAddress_asc" : "ipAddress_desc" },
      { label: "Device Name A-Z", value: "deviceName_asc" },
      { label: "Device Name Z-A", value: "deviceName_desc" },
      { label: "Serial Number ↑", value: "enclosureSerialNumber_asc" },
      { label: "Serial Number ↓", value: "enclosureSerialNumber_desc" },
      { label: "IP Address ↑", value: "ipAddress_asc" },
      { label: "IP Address ↓", value: "ipAddress_desc" },
    ]


    return (
      <div style={{ marginTop: "2vh", overflowX: "auto" }}>
        <div className="grid-x ">
          <div className="cell large-6 medium-6 small-12">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <input
                type="text"
                placeholder="Search for model number, device name, or serial number"
                value={this.state.searchBar}
                onChange={(e) => {
                  this.setState({ searchBar: e.target.value });
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    this.applyFilters();
                  }
                }}
              />
              <span
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  padding: "0.45rem",
                  height: "2.43rem",
                  margin: "0 0 1rem",
                  borderRadius: "0.1875rem",
                  // backgroundColor: "#49535E",
                  // color: "#fff",
                }}
                className="background-color-secondary"
                onClick={() => {
                  this.applyFilters();
                }}
              >
                <i className="material-icons" style={{ marginRight: "0px"}}>search</i>
              </span>
            </div>
          </div>
          {addGroupDeviceActive &&this.props.match.params.groupId &&
            <div className="cell large-6 medium-6 small-12" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
              <Link
                to={`/devices/groups/${this.props.match.params.groupId}/devices/edit`}
                style={{ color: "white" }}
              >
                <div className="button">Manage group devices</div>
              </Link>
          </div>
          }
        </div>
        <div className="grid-x">
          <div className="cell large-6">
            <div style={{ display: 'flex'}}>
              <span style={{ marginRight: "20px" }}>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#6b6b6b",
                    marginBottom: "5px",
                    marginLeft: "3px",
                  }}
                >
                  Filter by
                </div>
                <select
                  style={{
                    margin: "0px",
                  }}
                  onChange={(e) => {
                    this.setState({
                      connectionFilter: e.target.value
                    })
                  }}
                >
                  <option value="">All Status</option>
                  <option label="Connected">Connected</option>
                  <option label="Disconnected">Disconnected</option>
                </select>

              </span>
              <span>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#6b6b6b",
                    marginBottom: "5px",
                    marginLeft: "3px",
                  }}
                >
                  Sort by
                </div>
                <select
                  onChange={(e) => {
                    this.setState({
                      sortFilter: e.target.value
                    })
                  }}
                >
                  {sortOptions.map((option, i) => {
                    return (
                      <option key={i} value={option.value}>{option.label}</option>
                    )
                  })}

                  {/* <option value="">Default</option>
                  <option value="deviceName_asc">Device Name (Ascending)</option>
                  <option value="deviceName_desc">Device Name (Descending)</option>
                  <option value="enclosureSerialNumber_asc">Serial Number (Ascending)</option>
                  <option value="enclosureSerialNumber_desc">Serial Number (Descending)</option>
                  <option value="ipAddress_asc">IP Address (Ascending)</option>
                  <option value="ipAddress_desc">IP Address (Descending)</option> */}
                </select>

              </span>
            </div>
          </div>
          <div className="cell large-6">
            <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
              
            </div>
          </div>
        </div>
        
        <div className={`grid-row header`}>
          <div style={{ flex: columnWidths[0] }}></div>
          <div style={{ flex: columnWidths[1] }}>Model Number</div>
          <div style={{ flex: columnWidths[2] }}>Serial number</div>
          <div style={{ flex: columnWidths[3] }}>Device Name</div>
          <div style={{ flex: columnWidths[4] }}>IP Address</div>
          <div style={{ flex: columnWidths[9] }}>Last Update</div>
          <div style={{ flex: columnWidths[10] }}></div>
        </div>

        {devicesLoading && (
          <div style={{}}>
            {[null, null, null, null].map((item, index) => {
              return (
                <div className="grid-row-wrapper" key={index}>
                  <div className="grid-row">
                    <div style={{ flex: columnWidths[0] }}></div>
                    <div style={{ flex: columnWidths[1] }}>
                      <div className="skeleton-blank" />
                    </div>
                    <div style={{ flex: columnWidths[2] }}>
                      <div className="skeleton-blank" />{" "}
                    </div>
                    <div style={{ flex: columnWidths[9] }}>
                      <div className="skeleton-blank" />{" "}
                    </div>
                    <div style={{ flex: columnWidths[10] }}></div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {!devicesLoading && devices.length === 0 && (
          <React.Fragment>
            <div className="grid-row-wrapper">
              <div className="grid-row">
                <div
                  style={{
                    paddingLeft: "50px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div>No Devices Found</div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "40px",
              }}
            >
              <span className="button">
                <a
                  href="https://synaccess.readme.io/reference/connect-a-synlink-pdu"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white" }}
                >
                  Learn how to connect a SynLink PDU
                </a>
              </span>
            </div>
          </React.Fragment>
        )}
        <Pagination
          items={sortedFilteredDevices}
          key={JSON.stringify(sortedFilteredDevices.map(d => d.enclosureSerialNumber))}
          renderItem={(device, i) => {
            const foundConf = this.props.resources.conf.find(conf => conf.enclosureSerialNumber == device.enclosureSerialNumber);        
            let ipAddr;
            if (foundConf) {
              if (foundConf.ipAssign === "dhcp" && foundConf.dhcpAssignedIp) {
                ipAddr = foundConf.dhcpAssignedIp;
              } else if (foundConf.ipAssign === "static" && foundConf.staticIp) {
                ipAddr = foundConf.staticIp;
              }
            } else {
              ipAddr = '-';
            }
            
            const series = (() => {
              if (device.modelNumber) {
                if (
                  device.outletPwrMeasurementsSupported &&
                  device.outletSwitchingSupported
                  // device.inletEnergySupported
                ) {
                  return "SynLink Switched Pro+";
                } else if (
                  // device.inletEnergySupported &&
                  !device.outletPwrMeasurementsSupported &&
                  !device.outletSwitchingSupported
                ) {
                  return "SynLink Monitored";
                } else if (
                  device.inletCurrentSupported &&
                  device.outletSwitchingSupported
                ) {
                  if (device.modelNumber.startsWith("NP")) {
                    return "NetBooter";
                  } else {
                    return "SynLink Switched";
                  }
                } else {
                  return "";
                }
              } else {
                return "";
              }
            })();
            const modelLine = device.modelNumber
              ? `${series === "NetBooter" ? "" : device.formFactor} ${series} ${series === "NetBooter" ? "PDU" : ""}`
              : devicesLoaded
              ? "-"
              : "";
            const connectionEstablished = device.connectionState === "Connected";
            return (
              <div
                key={i}
                className={`grid-row-wrapper ${
                  device.connectionState === "Connected" ? "" : "disconnected"
                }`}
                title={
                  device.connectionState === "Connected" ? "" : "Disconnected"
                }
              >
                <div
                  className={`grid-row ${
                    connectionEstablished ? "connected" : "disconnected"
                  }`}
                >
                  <div
                    style={{
                      fontWeight: "600",
                      flex: columnWidths[0],
                      color: connectionEstablished ? "forestgreen" : "maroon",
                    }}
                    // className={connectionEstablished ? 'green' : 'red'}
                    title={
                      connectionEstablished
                        ? "Connection Established"
                        : "Connection Cannot Be Made"
                    }
                  >
                    <i className="material-icons" style={{ marginLeft: "4px" }}>
                      {connectionEstablished ? "cloud_done" : "cloud_off"}
                    </i>
                  </div>
                  <div
                    style={{
                      flex: columnWidths[1],
                    }}
                  >
                    <Link
                      to={`/devices/connected/${device.enclosureSerialNumber}`}
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <span
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          <b>
                          {
                              device.modelNumber
                                  ? device.modelNumber.startsWith("NP")
                                      ? device.modelNumber
                                      : `SP-${device.modelNumber}`
                                  : "-"
                          }
                          </b>
                        </span>
                        <span>{modelLine}</span>
                      </span>
                    </Link>
                  </div>
  
                  <div
                    style={{
                      // color: "rgba(0,0,0,0.7)",
                      flex: columnWidths[2],
                      // display: 'flex',
                      // flexDirection: 'column',
                      // alignItems: 'flex-start'
                    }}
                  >
                    {device.enclosureSerialNumber}                  
                  </div>
                  <div
                    style={{
                      flex: columnWidths[3],
                    }}
                  >
                    <span
                      className="hover-show"
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        this.editDeviceName(device);
                      }}
                    >
                      <span>
                        {device.deviceName}
                          {/* {device.deviceName === "SynLink PDU" ||
                          !device.deviceName
                            ? `SynLink PDU (${device.enclosureSerialNumber})`
                            : device.deviceName} */}
                      </span>
                      <span className="hover-item">
                        <i className="material-icons">edit</i>
                      </span>
                    </span>
                  </div>
  
                  <div
                    style={{
                      flex: columnWidths[4],
                      // fontWeight: "600",
                    }}
                  >
                    {this.props.resources.confLoading ? <div className="skeleton-blank" /> : <span>{ipAddr}</span>}
                    
                  </div>
                  <div style={{ flex: columnWidths[9] }}>
                    <span
                      style={{
                        fontSize: "90%",
                      }}
                    >
                      {device.lastUpdated &&
                        `${formatDistance(device.lastUpdated, new Date())} ago`}
                    </span>
                  </div>
                  <div style={{ flex: columnWidths[10] }}>
                    <span
                      title="Refresh PDU Information"
                      className="black"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        this.updateDevice(device.enclosureSerialNumber);
                      }}
                    >
                      <i
                        style={{ fontWeight: "600", color: "#6c6f72" }}
                        className="material-icons"
                      >
                        sync
                      </i>
                    </span>
                  </div>
                </div>
              </div>
            );
          }}
        />
        {/* {sortedFilteredDevices.map((device, i) => {
          const foundConf = this.props.resources.conf.find(conf => conf.enclosureSerialNumber == device.enclosureSerialNumber);        
          let ipAddr;
          if (foundConf) {
            if (foundConf.ipAssign === "dhcp" && foundConf.dhcpAssignedIp) {
              ipAddr = foundConf.dhcpAssignedIp;
            } else if (foundConf.ipAssign === "static" && foundConf.staticIp) {
              ipAddr = foundConf.staticIp;
            }
          } else {
            ipAddr = '-';
          }
          
          const series = (() => {
            if (device.modelNumber) {
              if (
                device.outletPwrMeasurementsSupported &&
                device.outletSwitchingSupported
                // device.inletEnergySupported
              ) {
                return "SynLink Switched Pro+";
              } else if (
                // device.inletEnergySupported &&
                !device.outletPwrMeasurementsSupported &&
                !device.outletSwitchingSupported
              ) {
                return "SynLink Monitored";
              } else if (
                device.inletCurrentSupported &&
                device.outletSwitchingSupported
              ) {
                if (device.modelNumber.startsWith("NP")) {
                  return "NetBooter";
                } else {
                  return "SynLink Switched";
                }
              } else {
                return "";
              }
            } else {
              return "";
            }
          })();
          const modelLine = device.modelNumber
            ? `${series === "NetBooter" ? "" : device.formFactor} ${series} ${series === "NetBooter" ? "PDU" : ""}`
            : devicesLoaded
            ? "-"
            : "";
          const connectionEstablished = device.connectionState === "Connected";
          return (
            <div
              key={i}
              className={`grid-row-wrapper ${
                device.connectionState === "Connected" ? "" : "disconnected"
              }`}
              title={
                device.connectionState === "Connected" ? "" : "Disconnected"
              }
            >
              <div
                className={`grid-row ${
                  connectionEstablished ? "connected" : "disconnected"
                }`}
              >
                <div
                  style={{
                    fontWeight: "600",
                    flex: columnWidths[0],
                    color: connectionEstablished ? "forestgreen" : "maroon",
                  }}
                  // className={connectionEstablished ? 'green' : 'red'}
                  title={
                    connectionEstablished
                      ? "Connection Established"
                      : "Connection Cannot Be Made"
                  }
                >
                  <i className="material-icons" style={{ marginLeft: "4px" }}>
                    {connectionEstablished ? "cloud_done" : "cloud_off"}
                  </i>
                </div>
                <div
                  style={{
                    flex: columnWidths[1],
                  }}
                >
                  <Link
                    to={`/devices/connected/${device.enclosureSerialNumber}`}
                  >
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        <b>
                        {
                            device.modelNumber
                                ? device.modelNumber.startsWith("NP")
                                    ? device.modelNumber
                                    : `SP-${device.modelNumber}`
                                : "-"
                        }
                        </b>
                      </span>
                      <span>{modelLine}</span>
                    </span>
                  </Link>
                </div>

                <div
                  style={{
                    // color: "rgba(0,0,0,0.7)",
                    flex: columnWidths[2],
                    // display: 'flex',
                    // flexDirection: 'column',
                    // alignItems: 'flex-start'
                  }}
                >
                  {device.enclosureSerialNumber}                  
                </div>
                <div
                  style={{
                    flex: columnWidths[3],
                  }}
                >
                  <span
                    className="hover-show"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      this.editDeviceName(device);
                    }}
                  >
                    <span>
                      {device.deviceName}
                    </span>
                    <span className="hover-item">
                      <i className="material-icons">edit</i>
                    </span>
                  </span>
                </div>

                <div
                  style={{
                    flex: columnWidths[4],
                    // fontWeight: "600",
                  }}
                >
                  {this.props.resources.confLoading ? <div className="skeleton-blank" /> : <span>{ipAddr}</span>}
                  
                </div>
                <div style={{ flex: columnWidths[9] }}>
                  <span
                    style={{
                      fontSize: "90%",
                    }}
                  >
                    {device.lastUpdated &&
                      `${formatDistance(device.lastUpdated, new Date())} ago`}
                  </span>
                </div>
                <div style={{ flex: columnWidths[10] }}>
                  <span
                    title="Refresh PDU Information"
                    className="black"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.updateDevice(device.enclosureSerialNumber);
                    }}
                  >
                    <i
                      style={{ fontWeight: "600", color: "#6c6f72" }}
                      className="material-icons"
                    >
                      sync
                    </i>
                  </span>
                </div>
              </div>
            </div>
          );
        })} */}
      </div>
    );
  }
}

const DeviceLineItem = (device) => {
  return (
    <div
      key={device.enclosureSerialNumber}
      style={{
        // display: 'flex'
        backgroundColor: "white",
        boxShadow: "3px 3px 10px rgba(0,0,0,0.2)",
        marginBottom: "10px",
        borderRadius: "3px",
      }}
      className="grid-x"
    >
      <div
        className="cell large-9 medium-9 small-12"
        style={{ borderRight: "solid #f8f8f8 3px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            borderBottom: "solid #f8f8f8 3px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className={
                "dot " +
                (device.connectionState === "Connected" ? "green" : "red")
              }
              style={{
                marginRight: "10px",
              }}
            />
            <div>
              {device.deviceName ||
                `SynLink PDU SN:${device.enclosureSerialNumber}`}
            </div>
          </div>

          <div>buttons</div>
        </div>
        <div
          style={{
            padding: "10px",
            paddingLeft: "35px",
          }}
        >
          button specs
        </div>
      </div>
      <div className="cell large-3 medium-3 small-12" style={{}}>
        call to action stuff
      </div>
    </div>
  );
};

const DataValue = ({ value, unit }) => {
  return (
    <span style={{ display: "flex", alignItems: "flex-end" }}>
      <span
        style={{
          fontWeight: "600",
          fontSize: "105%",
        }}
      >
        {value}
      </span>
      <span
        style={{
          fontSize: "80%",
          paddingLeft: "4px",
          paddingBottom: "2px",
        }}
      >
        {unit}
      </span>
    </span>
  );
};

class EditDeviceForm extends React.Component {
  state = {
    deviceName: "",
    loading: false,
  };
  componentDidMount() {
    this.setState({
      deviceName: this.props.device.deviceName,
    });
  }
  render() {
    const { device, close } = this.props;

    return (
      <div>
        <TextInput
          label="Device Name"
          value={this.state.deviceName}
          callback={(txt) => {
            this.setState({ deviceName: txt });
          }}
          placeholder="Enter Device Name"
        />
        <button
          className="button"
          onClick={() => {
            this.setState({ loading: true })
            this.props.modifyResource(
              "devices",
              device.enclosureSerialNumber,
              {
                deviceName: this.state.deviceName,
              },
              (err, res) => {
                this.setState({ loading: false })
                if (err) {
                  console.log(err);
                } else {
                  close();
                }
              },
              "enclosureSerialNumber"
            );
          }}
        >
          
          {this.state.loading ? <Loading /> : "Change"}
        </button>
      </div>
    );
  }
}
