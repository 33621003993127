import React from "react";
import axios from "axios";
import { formatDistanceStrict } from "date-fns";
// import DeviceInlets from "../deviceInlets";
import Loading from "../../../components/common/loading";
// import DateRangeInput from "../../../components/common/inputs/dateRangeInput";
// import LineChart from '../../../components/common/charts/lineChart';

// import chartColors from "../../../assets/chartColors";

import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line } from 'react-chartjs-2';
import RowList from "../../../components/common/rowList";

// Register Chart.js modules so time scale, tooltips, etc. work correctly:
ChartJS.register(
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default class DevicePageInlet extends React.Component {
  state = {
    inletLogs: [],
    inletLogsLoading: false,
    inletLogsLoaded: false,
    bankLogs: [],
    chartStartDate: (() => {
      const todaysUnixStartTime = new Date();
      todaysUnixStartTime.setHours(0, 0, 0, 0);
      return todaysUnixStartTime.getTime();
    })(), // default to last day
    chartEndDate: Date.now(),
    chartTimeFrameText: "Today",
  };
  // grabCsvData = async () => {
  //   const { enclosureSerialNumber } = this.props.match.params;

  //   const timeDiff = this.state.chartEndDate - this.state.chartStartDate;
  //   const granularityInMinutes = Math.round(timeDiff / 1000 / 1000 / 60) || 1;

  //   await axios({
  //     url: `/v1/logs/inlets?enclosureSerialNumber=${enclosureSerialNumber}&since=${this.state.chartStartDate}&before=${this.state.chartEndDate}${
  //       granularityInMinutes > 0
  //         ? `&granularity=${granularityInMinutes}&outputFormat=csv`
  //         : ""
  //     }`, //your url
  //     method: "GET",
  //     headers: {
  //       Authorization: "Bearer " + localStorage.getItem("token"),
  //     },
  //     responseType: "blob", // important
  //   }).then((response) => {
  //     const url = window.URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", "inlet_logs.csv"); //or any other extension
  //     document.body.appendChild(link);
  //     link.click();
  //   });
  // };
  // grabDataSet = async () => {
  //   const { enclosureSerialNumber } = this.props.match.params;
  //   const timeDiff = this.state.chartEndDate - this.state.chartStartDate;
  //   const granularityInMinutes = Math.round(timeDiff / 1000 / 1000 / 60) || 1;

  //   this.setState({ inletLogsLoading: true })
  //   const logs = await axios.get(
  //     `/v1/logs/inlets?enclosureSerialNumber=${enclosureSerialNumber}&since=${ this.state.chartStartDate}&before=${this.state.chartEndDate}${
  //       granularityInMinutes > 0 ? `&granularity=${granularityInMinutes}` : ""
  //     }`,
  //     {
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     }
  //   );
  //   this.setState({ inletLogsLoading: false, inletLogsLoaded: true })
  //   const foundDevice =
  //     this.props.resources.devices.find(
  //       (d) => d.enclosureSerialNumber == enclosureSerialNumber
  //     ) || {};
  //   if (foundDevice.numBanks > 1) {
  //     const bankLogs = await axios.get(
  //       `/v1/logs/banks?enclosureSerialNumber=${enclosureSerialNumber}&since=${ this.state.chartStartDate}&before=${this.state.chartEndDate}${
  //         granularityInMinutes > 0 ? `&granularity=${granularityInMinutes}` : ""
  //       }`,
  //       {
  //         headers: {
  //           Authorization: "Bearer " + localStorage.getItem("token"),
  //         },
  //       }
  //     )
  //     if (bankLogs.status === 200) {
  //       const arrayofBankLogs = bankLogs.data.data
  //         .reduce((sum, item) => {
  //           if (sum.find((bankLogs) => bankLogs.bank_id === item.bank_id)) {
  //             return sum.map((s) =>
  //               s.bank_id === item.bank_id
  //                 ? {
  //                     ...s,
  //                     items: [item, ...s.items],
  //                   }
  //                 : {
  //                     ...s,
  //                     items: s.items,
  //                   }
  //             );
  //           } else {
  //             return [
  //               ...sum,
  //               {
  //                 bank_id: item.bank_id,
  //                 // bankLines: item.bankLines,
  //                 items: [item],
  //               },
  //             ];
  //           }
  //         }, [])
  //         .sort((a, b) => a.bank_id.localeCompare(b.bank_id));
  //       this.setState({
  //         bankLogs: arrayofBankLogs
  //       })
  //     }

  //   }
  //   if (logs.status === 200) {
  //     const arrayOfInletLogs = (logs.data.data || [])
  //       .reduce((sum, item) => {
  //         if (sum.find((inletLogs) => inletLogs.inlet_id === item.inlet_id)) {
  //           return sum.map((s) =>
  //             s.inlet_id === item.inlet_id
  //               ? {
  //                   ...s,
  //                   items: [item, ...s.items],
  //                 }
  //               : {
  //                   ...s,
  //                   items: s.items,
  //                 }
  //           );
  //         } else {
  //           return [
  //             ...sum,
  //             {
  //               inlet_id: item.inlet_id,
  //               items: [item],
  //             },
  //           ];
  //         }
  //       }, [])
  //       .sort((a, b) => a.inlet_id.localeCompare(b.inlet_id));
  //     this.setState({
  //       inletLogs: arrayOfInletLogs.map((inletObj) => {
  //         return {
  //           ...inletObj,
  //           lineFrequencyMax: Math.max(
  //             ...inletObj.items.map((item) => item.lineFrequency)
  //           ),
  //           lineFrequencyMin: Math.min(
  //             ...inletObj.items.map((item) => item.lineFrequency)
  //           ),
  //         };
  //       }),
  //     });
  //   }
  // };
  async componentDidMount() {
    // this.grabDataSet();
    // if (!this.props.banksLoading && !this.props.banksLoaded) {
      // this.props.fetchResource("banks", (err, banks) => {
        
      // },`?enclosureSerialNumber=${this.props.match.params.enclosureSerialNumber}`);
    // }
    if (!this.props.resources.inletsLoaded && !this.props.resources.inletsLoading) {
      this.props.fetchResource("inlets");
    }
  }
  render() {
    const { enclosureSerialNumber } = this.props.match.params;
    const { inlets, banks, inletsLoading, inletsLoaded } = this.props.resources;
    // const { inletLogs, bankLogs } = this.state;
    const foundDevice =
      this.props.resources.devices.find(
        (d) => d.enclosureSerialNumber == enclosureSerialNumber
      ) || {};


    // const labels = inletLogs[0]
    //   ? inletLogs[0].items.map((i) => i.createdAt)
    //   : [];
    // const dataset = (field, items, itemId, customLabelFunc) =>
    //   items.map((logSetObj, index) => {
    //     return {
    //       label: customLabelFunc ? customLabelFunc(logSetObj) : logSetObj[itemId],
    //       data: logSetObj.items.map((i) => i[field]),
    //       borderColor: chartColors.primaryColors[index % 8],
    //       backgroundColor: chartColors.primaryColorHalfOpacity[index % 8],
    //     };
    //   });
  
    return (
      <div>
        <div className="grid-x grid-padding-x grid-padding-y">
          <div className="cell large-6">
            {inlets.length > 0 ? (
              inlets[0].inletPhase === "Three Phase" ? (
                <RowList
                  rows={[
                    {
                      label: "Inlet Stats",
                      labelStyle: {
                        fontWeight: "bold",
                        fontSize: "110%",
                      }
                    },
                    {
                      label: "Energy Consumption",
                      value: inlets[0].energyAccumulation,
                      unit: "kWh",
                    },
                    {
                      label: "Line 1 Current RMS",
                      value:
                        (
                          (inlets[0].inletLines || []).find(
                            (l) => l.line === "L1"
                          ) || {}
                        ).currentRms !== undefined
                          ? (
                              (inlets[0].inletLines || []).find(
                                (l) => l.line === "L1"
                              ) || {}
                            ).currentRms.toFixed(2)
                          : "-",
                    },
                    {
                      label: "Line 2 Current RMS",
                      value:
                        (
                          (inlets[0].inletLines || []).find(
                            (l) => l.line === "L2"
                          ) || {}
                        ).currentRms !== undefined
                          ? (
                              (inlets[0].inletLines || []).find(
                                (l) => l.line === "L2"
                              ) || {}
                            ).currentRms.toFixed(2)
                          : "-",
                    },
                    {
                      label: "Line 3 Current RMS",
                      value:
                        (
                          (inlets[0].inletLines || []).find(
                            (l) => l.line === "L2"
                          ) || {}
                        ).currentRms !== undefined
                          ? (
                              (inlets[0].inletLines || []).find(
                                (l) => l.line === "L2"
                              ) || {}
                            ).currentRms.toFixed(2)
                          : "-",
                    },
                  ]}
                />
              ) : (
                inlets.map((inlet, inletIndex) => {
                  return (
                    [
                    <RowList
                      key={inletIndex}
                      rows={[
                        {
                          label: inlets.length > 0 ? `Inlet ${inletIndex + 1}` : "Inlet Stats",
                          labelStyle: {
                            fontWeight: "bold",
                            fontSize: "110%",
                          },
                        },
                        {
                          label: "Inlet ID",
                          value: `${inlet.id}`,
                        },
                        {
                          label: "Current RMS",
                          value: <DataValue value={`${inlet.inletCurrentRms !== undefined ? `${inlet.inletCurrentRms.toFixed(2)}` : ""}`} unit="A" />
                          // value: 
                          //       `${inlet.inletCurrentRms !== undefined
                          //           ? `${inlet.inletCurrentRms.toFixed(2)} A`
                          //           : "-"
                          //       }`
                        },
                        {
                          label: "Voltage RMS",
                          value: <DataValue value={inlet.inletVoltageRms !== undefined
                                ? `${inlet.inletVoltageRms.toFixed(2)}`
                                : "-"} unit="V" />
                        },
                        {
                          label: "Line Frequency",
                          value: <DataValue value={inlet.inletLineFrequency !== undefined ? `${inlet.inletLineFrequency.toFixed(2)}` : "-"} unit="Hz" /> 
                        },
                        {
                          label: "Power Factor",
                          value: <DataValue value={inlet.inletPowerFactor !== undefined ? `${inlet.inletPowerFactor.toFixed(2)}` : "-"} unit="" />
                               
                        },
                        {
                          label: "Energy Consumption",
                          value: <DataValue value={inlet.inletEnergyAccumulation !== undefined ? `${inlet.inletEnergyAccumulation.toFixed(2)}` : "-"} unit="kWh" />
                        },
                        {
                          label: "Active Power",
                          value: <DataValue value={inlet.inletActivePower !== undefined ? `${inlet.inletActivePower.toFixed(2)}` : "-"} unit="W" />
                                // `${
                                //   inlet.inletActivePower !== undefined
                                //     ? `${inlet.inletActivePower.toFixed(2)} W`
                                //     : "-"
                                // }`
                        },
                        {
                          label: "Apparent Power",
                          value: <DataValue value={inlet.inletApparentPower !== undefined ? `${inlet.inletApparentPower.toFixed(2)}` : "-"} unit="VA" />
                                // `${
                                //   inlet.inletApparentPower !== undefined
                                //     ? `${inlet.inletApparentPower.toFixed(2)} VA`
                                //     : "-"
                                // }`
                        },
                      ]}
                    />,
                    <div style={{ marginBottom: "15px "}} />,
                    this.props.user.id=== "223" ? <InternalInletLogs
                      key={inletIndex}
                      inlet={inlet}
                    /> : null
                    ]
                    
                  )
                })
              )
            ): (
              inletsLoading ? (
                <RowList
                  rows={[{
                    label: <div className="skeleton-blank" />,
                    value: <div className="skeleton-blank" />
                  },{
                    label: <div className="skeleton-blank" />,
                    value: <div className="skeleton-blank" />
                  },{
                    label: <div className="skeleton-blank" />,
                    value: <div className="skeleton-blank" />
                  },{
                    label: <div className="skeleton-blank" />,
                    value: <div className="skeleton-blank" />
                  }]}                   
                />
              ) : (
                null
              )
            )}
            <br />
            {banks.length > 0 && (
              inlets[0] && (
                inlets[0].inletPhase === "Three Phase" ? (
                  <RowList
                    rows={[{
                      label: "Circuit Stats",
                      labelStyle: {
                        fontWeight: "bold",
                        fontSize: "110%",
                      }
                    }, {
                      label: "Circuits",
                      value: banks.map((bank) => `${bank.bankLines}`).join(", "),
                    }, {
                      label: "Current RMS",
                      value: banks
                        .map(
                          (bank) =>
                            `${
                              bank.currentRms !== undefined
                                ? `${bank.currentRms.toFixed(2)} A`
                                : "-"
                            }`
                        )
                        .join(", "),
                    },
                    {
                      label: "Voltage RMS",
                      value: banks
                        .map(
                          (bank) =>
                            `${
                              bank.voltageRms !== undefined
                                ? `${bank.voltageRms.toFixed(2)} V`
                                : "-"
                            }`
                        )
                        .join(", "),
                    },
                    {
                      label: "Line Frequency",
                      value: banks
                        .map(
                          (bank) =>
                            `${
                              bank.lineFrequency !== undefined
                                ? `${bank.lineFrequency.toFixed(2)} Hz`
                                : "-"
                            }`
                        )
                        .join(", "),
                    },
                    {
                      label: "Power Factor",
                      value: banks
                        .map(
                          (bank) =>
                            `${
                              bank.powerFactor !== undefined
                                ? `${bank.powerFactor.toFixed(2)}`
                                : "-"
                            }`
                        )
                        .join(", "),
                    },
                    {
                      label: "Energy Consumption",
                      value: banks
                        .map(
                          (bank) =>
                            `${
                              bank.energyAccumulation !== undefined
                                ? `${bank.energyAccumulation.toFixed(
                                    2
                                  )} kWh`
                                : "-"
                            }`
                        )
                        .join(", "),
                    },
                    {
                      label: "Active Power",
                      value: banks
                        .map(
                          (bank) =>
                            `${
                              bank.activePower !== undefined
                                ? `${bank.activePower.toFixed(2)} W`
                                : "-"
                            }`
                        )
                        .join(", "),
                    },
                    {
                      label: "Apparent Power",
                      value: banks
                        .map(
                          (bank) =>
                            `${
                              bank.apparentPower !== undefined
                                ? `${bank.apparentPower.toFixed(2)} VA`
                                : "-"
                            }`
                        )
                        .join(", "),
                    }]}
                  />
                ) : (
                  null
                )
              )
            )}
          </div>
          {inlets.length > 0 && (
            <div className="cell large-6" style={{ }}>
              <RowList
                rows={[
                  {
                    label: "Inlet Information",
                    labelStyle: {
                      fontWeight: "bold",
                      fontSize: "110%",
                    },
                  },

                  {
                    label: "Enclosure Serial number",
                    value: inlets[0].enclosureSerialNumber,
                  },
                  {
                    label: "Inlet Plug",
                    value: inlets[0].inletPlug,
                  },
                  {
                    label: "Inlet Type",
                    value:
                      inlets[0].inletType === "dual"
                        ? "Dual Circuit"
                        : inlets[0].inletType === "ats"
                        ? "ATS (Automatic Transfer Switch)"
                        : "Standard",
                  },
                  {
                    label: "Inlet Phase",
                    value: inlets[0].inletPhase,
                  },
                  {
                    label: "Connection State",
                    value: inlets[0].connectionState,
                  },
                  {
                    label: "Last Updated",
                    value: foundDevice.lastUpdated && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <span>
                          {formatDistanceStrict(
                            foundDevice.lastUpdated,
                            new Date()
                          )}{" "}
                          ago
                        </span>
                        {foundDevice.loading ? (
                          <span
                            style={{
                              // cursor: "pointer",
                              paddingLeft: "7px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Loading black />
                          </span>
                        ) : (
                          <span
                            title="Refresh PDU Information"
                            className="black"
                            style={{
                              cursor: "pointer",
                              paddingLeft: "7px",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              this.props.getDeviceItemAndData(
                                foundDevice.enclosureSerialNumber
                              );
                            }}
                          >
                            <i
                              style={{ fontWeight: "600", color: "#6c6f72" }}
                              className="material-icons"
                            >
                              sync
                            </i>
                          </span>
                        )}
                      </div>
                    ),
                    valueStyle: {
                      fontStyle: "italic",
                    },
                  },
                ]}
              />
            </div>
          )}
          {/* <div className="cell large-12">
            <div
              className="div"
              style={{
                marginBottom: "20px",
                marginTop: "40px",
                fontSize: "110%",
                fontWeight: "600",
              }}
            >
              Input Monitoring Logs
            </div>
            <DateRangeInput
              onDateChange={(firstDate, secondDate, text) => {
                this.setState(
                  {
                    chartStartDate: firstDate,
                    chartEndDate: secondDate,
                    chartTimeFrameText: text,
                  },
                  () => {
                    this.grabDataSet();
                  }
                );
              }}
              onExport = {() => {
                this.grabCsvData();
              }}
              firstDate={this.state.chartStartDate}
              endDate={this.state.chartEndDate}
              firstDataPointDate={
                inletLogs[0] ? inletLogs[0].items[0].createdAt : null
              }
              lastDataPointDate={
                inletLogs[0]
                  ? inletLogs[0].items[inletLogs[0].items.length - 1].createdAt
                  : null
              }
            />
          </div> */}
          {/* {this.state.inletLogsLoading && !this.state.inletLogsLoaded && (
            <React.Fragment>
            <div className="cell large-6">
              <div className="app-item" style={{
                height: "300px",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Loading black />
              </div>
            </div>
            <div className="cell large-6">
              <div className="app-item" style={{
                height: "300px",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <Loading black />
              </div>

            </div>
            </React.Fragment>
          )} */}
          {/* {[
            {
              wrapperClass: "large-auto",
              disabled:
                !inletLogs[0] || (inlets[0] || {}).inletPhase === "Three Phase",
              title: "Inlet Current RMS over time",
              dataField: "currentRms",
            },
            {
              disabled: !foundDevice.inletEnergySupported || !inletLogs[0],
              title: "Inlet Energy Consumption over time",
              dataField: "energyAccumulation",
            },
            {
              disabled:
                !foundDevice.inletEnergySupported ||
                !inletLogs[0] ||
                (inlets[0] || {}).inletPhase === "Three Phase",
              title: "Inlet Voltage RMS over time",
              dataField: "voltageRms",
            },
            {
              disabled:
                !foundDevice.inletEnergySupported ||
                !inletLogs[0] ||
                (inlets[0] || {}).inletPhase === "Three Phase",
              title: "Inlet Power Factor over time",
              dataField: "powerFactor",
            },
            {
              disabled:
                !foundDevice.inletEnergySupported ||
                !inletLogs[0] ||
                (inlets[0] || {}).inletPhase === "Three Phase",
              title: "Inlet Line Frequency over time",
              dataField: "lineFrequency",
              scales: {
                x: {
                  type: "time",
                },
                y: {
                  suggestedMax: (inletLogs[0] || {}).lineFrequencyMax * 1.001,
                  suggestedMin: (inletLogs[0] || {}).lineFrequencyMin * 0.999,
                  ticks: {
                    // padding: 100
                  },
                },
              },
            },
            {
              disabled:
                !foundDevice.inletEnergySupported ||
                !inletLogs[0] ||
                (inlets[0] || {}).inletPhase === "Three Phase",
              title: "Inlet Active Power over time",
              dataField: "activePower",
            },
          ].map((item) => {
            if (item.disabled) return null;
            return (

            <LineChart
              key={item.title}
              item={item}
              chartTimeFrameText={this.state.chartTimeFrameText}
              labels={labels}
              datasets= {dataset(item.dataField, inletLogs, "inlet_id")}
            />
            )
            // return (
            //   <div
            //     key={item.title}
            //     className={item.wrapperClass || "cell large-6"}
            //   >
            //     <div className="app-item">
            //       <Line
            //         options={{
            //           animation: {
            //             duration: 0,
            //           },
            //           elements: {
            //             point: { radius: 0 },
            //           },
            //           responsive: true,
            //           plugins: {
            //             legend: {
            //               position: "top",
            //             },
            //             title: {
            //               display: true,
            //               text: item.title,
            //             },
            //           },
            //           scales: {
            //             x: {
            //               type: "time",
            //               time: {
            //                 unit:
            //                   this.state.chartTimeFrameText === "last-week"
            //                     ? "day"
            //                     : undefined,
            //                 displayFormats: {
            //                   day: "dd MMM",
            //                 },
            //               },
            //             },
            //             y: {
            //               suggestedMax: 0.1,
            //             },
            //             ...(item.scales || {}),
            //           },
            //         }}
            //         data={{
            //           labels,
            //           datasets: dataset(item.dataField, inletLogs, "inlet_id"),
            //         }}
            //       />
            //     </div>
            //   </div>
            // );
          })}
          {inlets[0] && inlets[0].inletPhase === "Three Phase" &&
            <LineChart
              item={{
                title: "Three Phase Lines Current RMS over time",
              }}
              chartTimeFrameText={this.state.chartTimeFrameText}
              labels={labels}
              datasets= {[
                {
                  line: "L1",
                  field: "threePhaseLine1CurrentRms",
                },
                {
                  line: "L2",
                  field: "threePhaseLine2CurrentRms",
                },
                {
                  line: "L3",
                  field: "threePhaseLine3CurrentRms",
                },
              ].map((phase, ind) => ({
                label: phase.line,
                data: (inletLogs[0] || { items: [] }).items.map(
                  (i) => i[phase.field]
                ),
                borderColor: chartColors.primaryColors[ind % 8],
                backgroundColor:
                  chartColors.primaryColorHalfOpacity[ind % 8],
              }))}
            />
          }
          {inlets[0] && inlets[0].inletPhase === "Three Phase" &&
            [{
              title: "Circuits Current RMS over time",
              dataField: "currentRms",
            }, {
              title: "Circuits Voltage RMS over time",
              dataField: "voltageRms",
            }, {
              title: "Circuits Line Frequency RMS over time",
              dataField: "lineFrequency",
            }, {
              title: "Circuits Power Factor over time",
              dataField: "powerFactor",
            }, {
              title: "Circuits Active Power over time",
              dataField: "activePower",
            }, {
              title: "Circuits Energy Accumulation over time",
              dataField: "energyAccumulation",
            }].map(item => (
              <LineChart
                key={item.title}
                item={item}
                chartTimeFrameText={this.state.chartTimeFrameText}
                labels={labels}
                datasets={dataset(
                  item.dataField, 
                  bankLogs, 
                  "bank_id",
                  (logObj) => (banks.find(b => b.id == logObj.bank_id) || {}).bankLines || logObj.bank_id
                )}
              />
            ))
          } */}
        </div>
        <br />
        <br />
        <br />
      </div>
    );
  }
}



const DataValue = ({ value, unit }) => {
  return (
    <span style={{ display: "flex", alignItems: "flex-end" }}>
      <span
        style={{
          fontWeight: "600",
          fontSize: "105%",
        }}
      >
        {value}
      </span>
      <span
        style={{
          fontSize: "80%",
          paddingLeft: "4px",
          paddingBottom: "2px",
        }}
      >
        {unit}
      </span>
    </span>
  );
};



class InternalInletLogs extends React.Component {
  state = {
    data: [
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737467805
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737467926
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737468046
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737468166
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737468286
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737468406
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737468526
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737468646
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737468766
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737468886
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737469006
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737469126
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737469246
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737469366
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737469486
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737469607
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737469727
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737469847
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737469967
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737470087
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737470207
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737470327
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737470447
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737470567
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737470687
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737470807
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737470927
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737471047
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737471167
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737471288
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737471408
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737471528
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737471648
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737471768
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737471888
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737472008
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737472128
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737472248
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737472368
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737472488
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737472608
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737472728
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737472849
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737472969
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737473089
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737473209
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737473329
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737473449
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737473569
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737473689
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737473809
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737473929
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737474049
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737474169
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737474289
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737474409
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737474530
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737474650
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737474770
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737474890
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737475010
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737475130
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737475250
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737475370
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737475490
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737475610
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737475730
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737475850
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737475970
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737476091
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737476211
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737476331
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737476451
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737476571
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737476691
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737476811
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737476931
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737477051
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737477171
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737477291
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737477411
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737477531
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737477651
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737477772
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737477892
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737478012
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737478132
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737478252
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737478372
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737478492
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737478612
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737478732
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737478852
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737478972
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737479092
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737479212
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737479333
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737479453
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737479573
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737479693
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737479813
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737479933
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737480053
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737480173
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737480293
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737480413
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737480533
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737480653
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737480773
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737480893
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737481014
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737481134
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737481254
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737481374
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737481494
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737481614
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737481734
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737481854
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737481974
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737482094
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737482214
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737482334
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737482455
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737482575
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737482695
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737482815
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737482935
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737483055
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737483175
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737483295
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737483415
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737483535
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737483655
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737483775
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737483895
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737484016
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737484136
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737484256
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737484376
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737484496
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737484616
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737484736
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737484856
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737484976
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737485096
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737485216
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737485336
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737485456
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737485576
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737485696
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737485817
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737485937
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737486057
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737486177
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737486297
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737486417
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737486537
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737486657
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737486777
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737486897
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737487017
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737487137
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737487257
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737487377
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737487498
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737487618
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737487738
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737487858
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737487978
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737488098
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737488218
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737488338
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737488458
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737488578
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737488698
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737488818
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737488938
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737489059
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737489179
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737489299
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737489419
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737489539
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737489659
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737489779
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737489899
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737490019
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737490139
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737490259
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737490379
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737490500
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737490620
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737490740
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737490860
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737490980
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737491100
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737491220
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737491340
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737491460
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737491580
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737491700
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737491820
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737491940
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737492060
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737492181
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737492301
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737492421
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737492541
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737492661
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737492781
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737492901
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737493021
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737493141
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737493261
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737493381
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737493501
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737493621
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737493741
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737493862
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737493982
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737494102
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737494222
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737494342
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737494462
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737494582
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737494702
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737494822
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737494942
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737495062
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737495182
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737495303
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737495423
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737495543
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737495663
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737495783
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737495903
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737496023
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737496143
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737496263
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737496383
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737496503
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737496623
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737496743
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737496863
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737496984
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737497104
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737497224
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737497344
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737497464
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737497584
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737497704
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737497824
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737497944
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737498064
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737498184
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737498304
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737498424
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737498544
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737498665
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737498785
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737498905
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737499025
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737499145
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737499265
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737499385
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737499505
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737499625
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737499745
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737499865
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737499985
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737500105
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737500225
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737500346
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737500466
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737500586
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737500706
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737500826
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737500946
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737501066
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737501186
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737501306
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737501426
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737501546
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737501666
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737501786
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737501907
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737502027
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737502147
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737502267
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737502387
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737502507
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737502627
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737502747
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737502867
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737502987
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737503107
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737503227
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737503347
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737503467
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737503587
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737503708
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737503828
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737503948
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737504068
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737504188
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737504308
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737504428
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737504548
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737504668
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737504788
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737504908
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737505028
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737505148
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737505268
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737505389
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737505509
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737505629
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737505749
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737505869
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737505989
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737506109
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737506229
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737506349
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737506469
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737506590
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737506710
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737506830
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737506951
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737507071
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737507191
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737507311
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737507431
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737507551
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737507672
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737507792
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737507912
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737508032
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737508152
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737508273
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737508393
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737508513
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737508633
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737508753
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737508874
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737508994
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737509114
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737509234
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737509354
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737509475
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737509595
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737509715
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737509835
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737509955
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737510075
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737510196
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737510316
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737510436
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737510556
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737510677
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737510797
      },
      {
          "unit": "a",
          "value": 0,
          "ref": "I1-1503401",
          "timestamp": 1737510917
      }
  ]
  }

  render() {
    // const inlets = [inlet]
    return <AmpChart data={this.state.data} />

    
  }
}

const AmpChart = ({ data }) => {
  /**
   * data is expected to be an array of objects:
   * [
   *   { unit: "a", value: 0, ref: "I1-1503401", timestamp: 1737467805 },
   *   { unit: "a", value: 0, ref: "I1-1503401", timestamp: 1737467926 },
   *   ...
   * ]
   * 
   * timestamp is in seconds (typical UNIX epoch).
   */

  // Transform the data for Chart.js:
  const chartData = {
    datasets: [
      {
        label: 'Amperage (A)',
        data: data.map((point) => ({
          x: point.timestamp * 1000, // Convert seconds -> milliseconds
          y: point.value,
        })),
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.3)',
        tension: 0.2, // Slight curve
        fill: true,
      },
    ],
  };

  // Chart.js options
  const options = {
    responsive: true,
    maintainAspectRatio: false, // Let the height be controlled by the container
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: 'Amperage Over Time',
      },
    },
    scales: {
      x: {
        type: 'time', // Use date/time scale
        time: {
          unit: 'minute', // or "hour", "day", etc. depending on your data
        },
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Amps (A)',
        },
      },
    },
  };

  return (
    <div
      style={{
        width: '100%',
        height: '400px', // Adjust the chart height as needed
        margin: '0 auto',
      }}
    >
      <Line data={chartData} options={options} />
    </div>
  );
};
